//@ts-check

import { IonPage, IonContent, IonList, IonItemDivider, IonRouterOutlet } from '@ionic/react';
import { options, moon, sunny, thermometer, swapHorizontal, informationCircle,
  trendingDown, handRight } from 'ionicons/icons';
import React, { FC, createContext, useState } from 'react';
import './Tabs.css';

import { BwContext, ctxBits } from "../context/GlobalContext"

import { i18strings } from '../functions/i18';
import TabContext, { TabItem, TabHeader } from '../functions/myReactComponents';
import { Redirect, Route } from 'react-router';
import PageHeatingInfoRoom from './heating/PageHeatingInfoRoom';
import PageRoomList from './heating/PageRoomList';
import PageHeatingManualSet from './heating/PageHeatingManualSet';
import PageHeatingLow from './heating/PageHeatingLow';
import PageHeatingSummer from './heating/PageHeatingSummer';
import PageHeatingRegimeList from './heating/PageHeatingRegimeList';
import PageHeatingRegimeSet from './heating/PageHeatingRegimeSet';
import PageHeatingRoomSet from './heating/PageHeatingRoomSet';
import PageHeatingRoomMaxSet from './heating/PageHeatingRoomMaxSet';
import PageHeatingFloorSet from './heating/PageHeatingFloorSet';
import PageHeatingWTR from './heating/PageHeatingWTR';
import PageHeatingHDO from './heating/PageHeatingHDO';
import PageHeatingKCNET from './heating/PageHeatingKCNET';
import PageCoolingSet from './heating/PageCoolingSet';
import PageCoolingRoomSet from './heating/PageCoolingRoomSet';
import { useFullHeatingRoomList } from '../functions/roomlist';
import MassHeatAssign from './heating/MassHeatingRegimeAssignment';
import MassCoolAssign from './heating/MassCoolingRegimeAssignment';

const PageList: FC<{
  dvcdis: (userInvoked: boolean) => void,
}> = (props) => {
  return <IonPage>
  <TabHeader dvcdis={props.dvcdis} title={i18strings.title_tab_heating} />

  <IonContent>
  <BwContext.Consumer>{bits =>
    <IonList lines="none" hidden={!!(bits.bits & ctxBits.loadingH)}>
      <TabContext.Provider value={["heating", !(bits.bits & ctxBits.xmppOnline)]}>

    <IonItemDivider>{bits.bits & ctxBits.backcompat ? i18strings.back_compatibility : i18strings.subtitle_tab_heating}</IonItemDivider>
      <TabItem link="inforoom" title={i18strings.menu_heating_inforoom} icon={informationCircle} />
      <TabItem link="manuallist" title={i18strings.menu_heating_manualchange} icon={handRight} New />
      <TabItem link="low" title={i18strings.menu_heating_low} icon={moon} />
      <TabItem link="regimelist" title={i18strings.menu_heating_programsetting} icon={options} />
      <TabItem link="roomlist" title={i18strings.menu_heating_circuitsetting} icon={swapHorizontal} />
      <TabItem link="massheatassign" title={i18strings.menu_heating_masscircuitsetting} icon={swapHorizontal} />
      <TabItem link="roommaxlist" title={i18strings.menu_heating_maxtempchange} icon={thermometer} New />
    {bits.bits & ctxBits.useWtr ?
      <TabItem link="wtr" title={i18strings.menu_heating_outdoortemp} icon={thermometer} />:""}
      <TabItem link="floorlist" title={i18strings.menu_heating_minfloortemp} icon={thermometer} New />
    {bits.bits & ctxBits.useHdo ?
      <TabItem link="hdo" title={i18strings.menu_heating_tariff} icon={trendingDown} New />:""}
    {bits.bits & ctxBits.useKcnet ?
      <TabItem link="kcnet" title={i18strings.menu_heating_kcnet} icon={informationCircle} />:""}
      <TabItem link="summer" title={i18strings.menu_heating_summer} icon={sunny} />
    {!(bits.bits & ctxBits.backcompat) ?
      <IonItemDivider>{i18strings.menu_cooling}</IonItemDivider> : '' }
      <TabItem link="coolingset" title={i18strings.menu_cooling_setting} icon={thermometer} New />
      <TabItem link="coolingroomlist" title={i18strings.menu_cooling_circuitsetting} icon={swapHorizontal} New hidden={!(bits.bits & ctxBits.cooling)} />
      <TabItem link="masscoolassign" title={i18strings.menu_cooling_masscircuitsetting} icon={swapHorizontal} />
      </TabContext.Provider>
    </IonList>
  }</BwContext.Consumer>
  </IonContent>
</IonPage>
}

export const hroomctx = createContext<ReturnType<typeof useFullHeatingRoomList>>({
  loading: false,
  error: "Room context not provided",
  entries: undefined,
  reload: () => {}
});

const TabHeating: FC<{
  dvcdis: (userInvoked: boolean) => void,
}> = (props) => {
  const [refresh, setRefresh] = useState(false);
  const rooms = useFullHeatingRoomList();
  
  return (
  <IonPage>
    <hroomctx.Provider value={rooms}>
      <IonRouterOutlet>
        <Route exact path="/heating" render={() => 
        <BwContext.Consumer>{bits =>
          (bits.bits & (ctxBits.roller | ctxBits.heating | ctxBits.loadingH)) === ctxBits.roller
          ? <Redirect to="/roller" />
          : <PageList {...props} />
        }</BwContext.Consumer>
        } />

        <Route exact path="/heating/inforoom" component={PageHeatingInfoRoom} />
        <Route exact path="/heating/manuallist" render={() =>
          <PageRoomList lnk="manuallist" title={i18strings.menu_heating_manualchange}
            icon={handRight} subt={i18strings.select_room} />} />
        <Route path="/heating/manuallist/room/:id/:name" render={props =>
          <PageHeatingManualSet {...props} />} />
        <Route exact path="/heating/low" component={PageHeatingLow} />
        <Route exact path="/heating/summer" component={PageHeatingSummer} />
        <Route exact path="/heating/regimelist" render={() => <PageHeatingRegimeList refresh={refresh}/>} />
        <Route path="/heating/regimelist/regime/:id/:name" render={(props) =>
          <PageHeatingRegimeSet {...props} setRefresh={setRefresh} refresh={refresh} />} />
        <Route exact path="/heating/roomlist" render={()=>
          <PageRoomList lnk="roomlist" title={i18strings.menu_heating_circuitsetting}
            icon={swapHorizontal} subt={i18strings.menu_heating_roomlist_subtitle} />} />
        <Route exact path="/heating/massheatassign" component={MassHeatAssign} />
        <Route path="/heating/roomlist/room/:id/:name" component={PageHeatingRoomSet}/>
        <Route exact path="/heating/roommaxlist" render={()=>
          <PageRoomList lnk="roommaxlist" title={i18strings.menu_heating_maxtempchange}
            icon={thermometer} subt={undefined} />} />
        <Route path="/heating/roommaxlist/room/:id/:name" component={PageHeatingRoomMaxSet} />
        <Route exact path="/heating/floorlist" render={()=>
          <PageRoomList lnk="floorlist" title={i18strings.menu_heating_minfloortemp}
            icon={thermometer} subt={i18strings.menu_heating_minfloorselect} />} />
        <Route path="/heating/floorlist/room/:id/:name" component={PageHeatingFloorSet} />
        <Route exact path="/heating/wtr" component={PageHeatingWTR} />
        <Route exact path="/heating/hdo" component={PageHeatingHDO} />
        <Route exact path="/heating/kcnet" component={PageHeatingKCNET} />

        <Route exact path="/heating/coolingset" component={PageCoolingSet} />
        <Route exact path="/heating/coolingroomlist" render={()=>
          <PageRoomList lnk="coolingroomlist" title={i18strings.menu_cooling_circuitsetting}
            icon={swapHorizontal} subt={i18strings.menu_cooling_roomlist_subtitle} />} />
        <Route path="/heating/coolingroomlist/room/:id/:name" component={PageCoolingRoomSet} />
        <Route exact path="/heating/masscoolassign" component={MassCoolAssign} />
      </IonRouterOutlet>
    </hroomctx.Provider>
  </IonPage>
  );
};

export default TabHeating;
