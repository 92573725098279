//@ts-check
import LocalizedStrings from 'react-localization';

const en = {
  help: 'Help',
  warning: 'Warning',
  error_login: 'Error logging in',
  error_login_otherUser: 'Another device is logged in. After {0}s of inactivity, it will be automatically logged out.',
  error_server: 'Server error: ',
  error_disconnect: "The control unit doesn't communicate, something wring is with the control unit.",
  error_checkcommunication: "Check the status of W+X at the control unit. If it contains a checkmark, there should be no problem. If there is a 2, make sure the control unit is connected and has correct login credentials",
  title_app: 'Heating control HC64',
  title_app_welcome: 'Welcome to app BMR Heating Control!',
  title_info: 'Information',
  title_version: 'Application version:',
  title_register: 'Register account',
  title_register1: "If you don't have a log in name and password, register your account. {0}",
  title_devices: 'Control units HC64',
  title_tab_heating: 'Heating control',
  subtitle_tab_heating: 'Heating',
  title_tab_roller: 'Roller shutters',
  title_tab_roller_short: 'Roller sh.',
  title_tab_settings: 'Settings',
  subtitle_tab_setting: 'About unit',
  title_tab_logout: 'Logout',
  button_close: 'Close',
  button_yes: 'Yes',
  button_no: 'No',
  button_delete_settings: 'Delete all settings',

  login: 'Login',
  login_input: 'Enter the name and password obtained from the registration:',
  login_name: 'Login name:',
  login_password: 'Password:',
  login_remember: 'Remember this profile',
  login_remember_pwd: "Remember password",
  login_forgot_password: 'Forgotten password',
  login_timeout: 'Logging out in 10 seconds ...',
  login_profile: "Profile",
  login_profile_new: "New...",
  login_forget_prompt: 'Do you wish to delete this profile?',
  login_import_profiles: "Import",
  login_export_profiles: "Export",
  login_import_success: "Successfully imported profiles",
  login_import_fail: "Failed to load profiles",
  login_export_success: "Successfully exported profiles",
  login_ie_profs: "Import / export login profiles",

  menu_devicelist_title: 'Device list',
  menu_devicelist_subtitle: 'Device associated with the account ',
  menu_devicelist_text: 'Select device for setting.',
  menu_heating_inforoom: 'Heating circuits information',
  menu_heating_manualchange: 'Manual temperature change',
  menu_heating_low: 'Low program',
  menu_heating_summer: 'Summer program',
  menu_heating_summer_on: 'Summer mode is ON',
  menu_heating_summer_off: 'Summer mode is OFF',
  menu_heating_regimelist_subtitle: 'List of heating regimes. Select a regime for setting.',
  menu_heating_regimeset: 'Regime setting',
  menu_heating_regimedelete: 'Delete the regime with all changes',
  menu_heating_programsetting: 'Regime setting',
  menu_heating_circuitsetting: 'Regime assignment',
  menu_heating_masscircuitsetting: 'Mass regime assignment',
  menu_heating_roomlist_subtitle: 'Select circuit for setting',
  menu_heating_maxtempchange: 'Max. temperature change',
  menu_heating_outdoortemp: 'Outdoor temperature',
  menu_heating_minfloortemp: 'Minimum floor temperature',
  menu_heating_minfloorselect: 'Select circuit for change of minimum floor temperature',
  menu_heating_previousregime_warning: 'Empty regimes can not be followed by asigned ones',
  menu_heating_tariff: 'Tariff',

  menu_heating_kcnet: 'KCNET information',
  menu_heating_kcnet_unused: 'unused',
  menu_heating_kcnet_error: 'undetected',
  menu_heating_kcnet_on: 'ON',
  menu_heating_kcnet_off: 'OFF',
  menu_heating_kcnet_id: 'KCNET ID',

  menu_heating_week_start: 'Starting week',
  menu_heating_1st_week_regimes: 'Regimes for 1st week',
  menu_heating_2nd_week_regimes: 'Regimes for 2nd week',
  menu_heating_3rd_week_regimes: 'Regimes for 3rd week',
  menu_heating_1st_week: '1st week',
  menu_heating_2nd_week: '2nd week',
  menu_heating_3rd_week: '3rd week',
  menu_heating_wtr_externaltemperature: 'Outdoor temp',
  menu_heating_wtr_undetected: 'WTR01 undetected',
  menu_heating_hdo_disabled: 'Disabled',
  menu_heating_hdo_low: '1st tariff',
  menu_heating_hdo_hight: '2nd tariff',
  menu_heating_hdo_unknown: 'Unknown',

  menu_cooling: 'Cooling',
  menu_cooling_setting: 'Cooling setting',
  menu_cooling_circuitsetting: 'Regime assignment',
  menu_cooling_masscircuitsetting: 'Mass regime assignment',
  menu_cooling_roomlist_subtitle: 'Select circuit for setting',
  menu_cooling_set_modes: 'Cooling modes',
  menu_cooling_set_mode0: 'Disabled',
  menu_cooling_set_mode1: 'WTR01',
  menu_cooling_set_mode2: 'HCS64',
  menu_cooling_set_exttemp_activation: 'Outdoor temp for activation',
  menu_cooling_set_hyst: 'Cooling hysterese',

  menu_roller_inforoom: 'Roller shutters information',
  menu_roller_actualposition: 'Current position',
  menu_roller_settingposition: 'Current setting',
  menu_roller_settings: 'Assignment to roller shutters',
  menu_roller_central_button: 'Central button',
  menu_roller_central_error: 'Central button communication error',
  menu_roller_central_on: 'Central button is ON',
  menu_roller_central_off: 'Central button is OFF',
  menu_roller_manualchange: 'Manual change',
  menu_roller_manualsend: 'Send command',
  menu_roller_manualselect: 'Select roller shutter',
  menu_roller_programsettings: 'Program setting',
  menu_roller_circuitsetting: 'Roller circuits setting',
  menu_roller_masscircuitsetting: 'Roller circuits setting',
  menu_roller_windsensor: 'Wind sensor',
  menu_roller_position: 'Position',
  menu_roller_position0: 'Open',
  menu_roller_position1: 'Close',
  menu_roller_position2: 'Gaps',
  menu_roller_position3: 'Undefined',
  menu_roller_rotation: 'Rotation',
  menu_roller_regimeset: 'Regime setting',
  menu_roller_regimedelete: 'Delete regime',
  menu_roller_regimewarning1: 'Set one day or an entire week',
  menu_roller_regimewarning2: 'The previous regime must not be empty.',

  menu_roller_windsensor_on: 'Sensor is ON',
  menu_roller_windsensor_off: 'Sensor is OFF',
  menu_roller_windsensor_undetected: 'Wind sensor is undetected',
  menu_roller_windsensor_speed: 'Wind speed',
  menu_roller_windsensor_alarm_speed: 'Wind speed during last alarm',
  menu_roller_windsensor_alarm: 'Wind ALARM!',
  menu_roller_windsensor_lastalarm: 'Last wind alarm',

  menu_setting_fw: 'Firmware version',
  menu_setting_fw_undetected: 'Undetected',

  menu_setting_datetime: 'Date time setting',
  menu_setting_datetime1: 'Date time',
  menu_setting_datetime_text: 'Date time setting. The current values are important for correct functioning of the control.',
  menu_setting_datetime_getcurrenttime: 'Get current time',

  back_compatibility: 'Heating - Backward compatibility mode',
  action_loading: 'Loading ...',
  action_pooling_data: 'Drag down to reload the data',
  action_settings_saved: 'Settings saved ...',
  action_save: 'Save',
  action_save_circuit: 'Save circuits',
  action_back: 'Back',
  select_room: 'Select a room for setting',
  temperature_actual: 'Current temperature',
  temperature_program: 'Program temperature',
  temperature_max_change: 'Maximum temperature cahnge',
  temperature_maxoffset: 'Max. allowed change',
  temperature_total: 'Total set temperature',
  temperature_manualchange: 'Manual temperature change',
  temperature_low: 'Low temperature',
  low_activate: 'LOW activated',
  low_deactivate: 'LOW deactivated',
  low_datetimeLowStart: 'Start date time',
  low_datetimeLowStop: 'Stop date time',
  low_setlow: 'Circuit assigned to Low program',
  low_confirm_end: 'Do you want to set a date time of end LOW?',
  low_confirm_end_tooltip: 'LOW deactivates at specified time',

  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
  confirm: 'Confirm',
  confirm_delete_settings: 'Do you want to delete all setting?',

  help_cooling_roomset: 'A cooling mode must be assigned to a given day of the week. \n'+
    'The modes are repeated in cycles. \n'+
    'If you fill in the Monday mode, this mode will be repeated every day. \n'+
    'If you fill in Monday-Sunday, the whole week will be repeated.',
  help_cooling_set: 'Cool modes:',
  help_cooling_set1: 'Disabled - Cooling not used',
  help_cooling_set2: 'WTR01 - cooling is controlled by the outdoor temperature module WTR01',
  help_cooling_set3: 'HCS64 - cooling is controlled by an external signal from the HCS64 module',
  help_cooling_set4: 'Outdoor activation temperature is the temperature at which cooling is turned on.',
  help_cooling_set5: 'Cooling is switched off at Outdoor activation temperature minus hysteresis.',
  help_cooling_set6: 'When changing the cooling mode, you must log out / log in to update the new settings.',

  help_heating_low: 'Turning on the Low program sets the attenuation temperature for selected rooms.\n'+
  'The beginning of the attenuation is always current time. The end of the attenuation can be planned for a later time.\n'+
  'By default, all rooms are selected.',
  help_heating_summer: 'Turning on the Summer program will set the temperature as follows:',
  help_heating_summer1: '0 ° C for electric heating',
  help_heating_summer2: '99 ° C for water heating; the KC64 end element switches off the heating source, the valves are regularly closed and opened at midnight as a protection against wearing',
  help_heating_summer3: 'By default, all rooms are selected for summer program.',
  help_heating_floorset: 'You can set the minimum floor temperature for the selected room. This setting can cause increased power consumption. Setting 0°C disables this feature.',
  help_heating_hdo: 'Electric heating controller RT64 series is equipped with blocking of heating in case of a 2nd tariff. Tariff information is not provided for water heating.',
  help_heating_roommaxset: 'The maximum possible temperature of manual change can be specified for the selected room.',
  help_heating_inforoom: 'Display of current, set temperature and manual change.',
  help_heating_inforoom1: 'String format: current temperature / set temperature',
  help_heating_inforoom2: 'Drag down to reload the data. Manual changes are marked yellow.',
  help_heating_inforoom3: 'By clicking on a room, you can go to the regime assignment.',
  help_heating_inforoom4: 'You can manually change the temperature by moving the line to the left.',
  help_heating_inforoom5: 'Click on the thermometer icon to display all temperatures, including logically connected floor sensors.',
  heating_info_icon_manual: "Manual change",
  heating_info_icon_heating: "Heating",
  heating_info_icon_low: "Low program",
  heating_info_icon_summer: "Summer program",
  heating_info_icon_open: "Open window",
  heating_info_icon_guest: "Guest",
  heating_info_icon_cool: "Cooling",
  heating_info_icon_err: "Sensor error",
  heating_info_icon_inactive: "Deactivated",
  help_heating_roomset: 'Heating regimes are to be assigned to days of the week. The modes are repeated in cycles. If you fill in the Monday mode, this mode will be repeated every day. If you fill in Monday-Sunday, the whole week will be repeated.\n'+
  'The whole cycle can be set for up to three weeks. When setting up a multi-week cycle, the starting week must be specified.',
  help_heating_roomset1: 'The day highlighted in blue indicates the current day in a multi-day cycle.',
  help_regime_assignment: 'Tapping on the {ic} icon opens the settings of selected regime.',
  help_heating_kcnet: 'The KCNET end element switches off the heating source if all rooms are heated to the required temperature.',
  help_heating_kcnet1: 'There can be up to 8 KCNET and one KCNET global in a water heating system.',
  help_heating_kcnet2: 'Status of KCNET:',
  help_heating_kcnet3: 'unused',
  help_heating_kcnet4: 'undetected - error communicating',
  help_heating_kcnet5: 'ON - heating source is ON',
  help_heating_kcnet6: 'OFF - heating source is OFF',
  help_heating_kcnet7: 'The end members for the heating are indicated by the sun icon',
  help_heating_kcnet8: 'The end members for the cooling are indicated by the snowflake icon',
  help_heating_manualset: 'Desired temperature in the selected room can be manually adjusted within the specified limits.\n'+
    'This adjustment is automatically reset with the next change of temperature by a heating regime.',
  help_heating_regimeset: 'Temperature mode setting. Up to 8 changes per day are available. The first mode always starts at 00:00.',
  help_heating_wtr: 'The optional WTR01 outdoor temperature module allows you to display outdoor temperature. The module can be assigned a heating regime according to which the output relay switches.',

  help_roller_inforoom: 'Display of position, set position and manual change.',
  help_roller_inforoom1: 'Drag down to reload a data. Manual changes are marked yellow.',
  help_roller_inforoom2: 'By clicking on the selected room, you can go to the mode assignment.',
  help_roller_inforoom3: 'You can manually change the position by moving the line to the left.',
  help_roller_inforoom4: 'The current position is given in % of travel time. Position 0% means open, position 100% is closed. The tilt of the slats at the blinds is from 0-horizontally to 10-vertically.',
  help_roller_inforoom5: 'Status of roller shutters:',
  help_roller_inforoom6: 'Roller shutter',
  help_roller_inforoom7: 'Blind',
  help_roller_inforoom8: 'Disabled',
  help_roller_inforoom9: 'Roller shutters responds to the central button',
  help_roller_inforoom10: 'A manual change has been made',
  help_roller_inforoom11: 'v5 - Validitate of communications',
  help_roller_inforoom12: 'e1 - Power supply error when moving down, no current detected',
  help_roller_inforoom13: 'e2 - Power supply error when moving up, no current detected',
  help_roller_inforoom14: 'e3 - Power supply error when moving down, overcurrent detected',
  help_roller_inforoom15: 'e4 - Power supply error when moving up, overcurrent detected',

  help_roller_regimeset: 'Up to 4 changes per day are available. The first mode always starts at 00:00. For blinds you can specify the slat tilt from 0 to 10, where: 0-horizontal position, 10-vertical position (closed).',
  help_roller_manual: 'For the selected roller shutter, the position can be temporarily changed manually, as by operating directly with the roller shutter buttons.',
  help_roller_central: 'Turning on the central button sets all assigned blinds to the position asigned to the central button. By default, all roller shutters or blinds respond to the central button. Select blinds can be excluded from the central function.',
  help_roller_wind: 'The wind sensor ensures protection of the blinds when the given wind speed is exceeded.\n'+
  'If the values ​​are exceeded, the blinds open.',
  help_roller_room: 'A roller shutter mode must be assigned to a given day of the week. \n'+
  'The modes are repeated in cycles. \n'+
  'If you fill in the Monday mode, this mode will be repeated every day. \n'+
  'If you fill in Monday-Sunday, the whole week will be repeated.\n'+
  'The entire cooling cycle can be one-week only.',
  help_devicelist: 'List of found HC64 control units assigned to this account. Select the HC64 control unit for setting.',
  title_messages: "Messages",
  button_dismiss: "Dismiss",
  button_dismiss_all: "Dismis all",
  button_show_all: "Show all",
  slides_cooling: "Only available when cooling is disabled. ",

  info1: "HC64 control unit remote control",
  info2: "Implemented are functions for controling heating, cooling and roller shutters.",
  info3: "Safe communication over SSL/TLS is active.",
  login_delete: "Delete account",

  title_theme: "Theme",
  theme_dark: "Dark",
  theme_light: "Light",

  heating_samples: "Sample regimes",
  heating_samples_antifreeze: "Anti-freeze",
  heating_samples_comfort: "Comfortable",
  heating_samples_max_floors: "Floor maximum",
  heating_samples_work: "Work day",
  heating_samples_wend: "Weekend",
  heating_samples_vestibule: "Vestibule",
  heating_samples_bath_floor: "Bathroom floor",
  heating_samples_bath_ladder: "Bathroom ladder",

  title_language: "Language",
  roller_alarm_off: "Wind speed is below limit or exceeds limit only for short times",

  button_frame_close: "Return to app",

  sort_title: "Sorted",
  sort_id: "by ID",
  sort_abc: "alphabetically",
  sort_default: "Default sorting: ",
  roller_manual_all: "Apply to all",

  button_cancel: "Cancel",
  button_ok: "Confirm",

  app_settings: "App settings",
  more_rooms: "more",
}

const cs : typeof en = {
  help: 'Nápověda',
  warning: 'Upozornění',
  error_login: 'Nesprávné přihlašovací údaje',
  error_login_otherUser: 'Je přihlášený jiný uživatel. Po {0}s nečinnosti bude automaticky odhlášen.',
  error_server: 'Chyba serveru: ',
  error_disconnect: "Řídicí jednotka nekomunikuje, problém je na řídicí jednotce.",
  error_checkcommunication: 'Zkontrolujte na řídicí jednotce status W+X. Pokud obsahuje zatržítko, vše je v pořádku. Pokud obsahuje 2, zkontrolujte, jestli je řídicí jednotka připojená a má správně nastavené přihlašovací údaje',
  title_app: 'Ovládání BMR HC64',
  title_app_welcome: 'Vítejte v aplikaci BMR pro ovládání řídící jednotky regulace vytápění, rolet/žaluzií.',
  title_info: 'Informace',
  title_version: 'Verze aplikace:',
  title_register: 'Registraci uživatele',
  title_register1: 'Pokud nemáte k dispozici přihlašovací údaje, proveďte {0}.',
  title_devices: 'Řídící jednotky HC64',
  title_tab_heating: 'Regulace vytápění',
  subtitle_tab_heating: 'Topení',
  title_tab_roller: 'Rolety',
  title_tab_roller_short: 'Rolety',
  title_tab_settings: 'Nastavení',
  subtitle_tab_setting: 'Informace o jednotce',
  title_tab_logout: 'Odhlásit',
  button_close: 'Zavřít',
  button_yes: 'Ano',
  button_no: 'Ne',
  button_delete_settings: 'Smazat všechna přiřazení',

  login: 'Přihlásit',
  login_input: 'Zadejte uživatelské jméno a heslo získané při registraci:',
  login_name: 'Přihlašovací jméno:',
  login_password: 'Heslo:',
  login_remember: 'Pamatovat si profil',
  login_remember_pwd: "Pamatovat si heslo",
  login_forgot_password: 'Zapomenuté heslo',
  login_timeout: 'K odhlášení dojde za 10s nečinnosti ...',
  login_profile: "Profil",
  login_profile_new: "Nový...",
  login_forget_prompt: "Chcete tento  profil smazat?",
  login_import_profiles: "Importovat",
  login_export_profiles: "Exportovat",
  login_import_success: "Profily byly načteny",
  login_import_fail: "Profily nelze načíst",
  login_export_success: "Profily byly uloženy",
  login_ie_profs: "Importovat / exportovat přihlašovací profily",

  menu_devicelist_title: 'Výpis řídících jednotek HC64',
  menu_devicelist_subtitle: 'Jednotky přiřazené k účtu ',
  menu_devicelist_text: 'Vyberte název jednotky, kterou chcete použít pro nastavení regulace.',
  menu_heating_inforoom: 'Informace o místnostech',
  menu_heating_manualchange: 'Ruční změna teploty',
  menu_heating_low: 'Útlum vytápění',
  menu_heating_summer: 'Letní režim',
  menu_heating_summer_on: 'Léto zapnuto',
  menu_heating_summer_off: 'Léto vypnuto',
  menu_heating_regimelist_subtitle: 'Výpis dostupných teplotních režimů. Vyberte režim pro úpravu teploty nebo času.',
  menu_heating_regimeset: 'Nastavení režimu',
  menu_heating_regimedelete: 'Smazat celý režim',
  menu_heating_programsetting: 'Teplotní režimy',
  menu_heating_circuitsetting: 'Přiřazení režimů okruhům',
  menu_heating_masscircuitsetting: 'Hromadné přiřazení režimů',
  menu_heating_roomlist_subtitle: 'Vyberte místnost pro přiřazení topných režimů.',
  menu_heating_maxtempchange: 'Maximální změna teploty',
  menu_heating_outdoortemp: 'Venkovní teplota (modul WTR01)',
  menu_heating_minfloortemp: 'Minimální teplota podlahy',
  menu_heating_minfloorselect: 'Vyberte místnost pro změnu minimální teploty podlahy',
  menu_heating_previousregime_warning: 'Po prázdných režimech nesmí následovat přiřazené režimy',
  menu_heating_tariff: 'Informace o tarifu',

  menu_heating_kcnet: 'Status koncových členů',
  menu_heating_kcnet_unused: 'nepoužit',
  menu_heating_kcnet_error: 'nedetekován',
  menu_heating_kcnet_on: 'Zapnuto - topí',
  menu_heating_kcnet_off: 'Vypnuto - netopí',
  menu_heating_kcnet_id: 'Číslo KCNET',

  menu_heating_week_start: 'Počáteční týden',
  menu_heating_1st_week_regimes: 'Přiřazení režimů pro 1.týden',
  menu_heating_2nd_week_regimes: 'Přiřazení režimů pro 2.týden',
  menu_heating_3rd_week_regimes: 'Přiřazení režimů pro 3.týden',
  menu_heating_1st_week: '1. týden',
  menu_heating_2nd_week: '2. týden',
  menu_heating_3rd_week: '3. týden',
  menu_heating_wtr_externaltemperature: 'Venkovní teplota',
  menu_heating_wtr_undetected: 'WTR01 nezjištěn',
  menu_heating_hdo_disabled: 'Tarif neaktivní',
  menu_heating_hdo_low: 'Nízký tarif',
  menu_heating_hdo_hight: 'Vysoký tarif',
  menu_heating_hdo_unknown: 'Nezjištěno',

  menu_cooling: 'Chlazení',
  menu_cooling_setting: 'Nastavení chlazení',
  menu_cooling_circuitsetting: 'Přiřazení režimů okruhům',
  menu_cooling_masscircuitsetting: 'Hromadné přiřazení režimů',
  menu_cooling_roomlist_subtitle: 'Vyberte místnost pro přiřazení chladících režimů.',
  menu_cooling_set_modes: 'Chladící módy',
  menu_cooling_set_mode0: 'Deaktivováno',
  menu_cooling_set_mode1: 'WTR01',
  menu_cooling_set_mode2: 'HCS64',
  menu_cooling_set_exttemp_activation: 'Venkovní teplota aktivace',
  menu_cooling_set_hyst: 'Hystereze chlazení',

  menu_roller_inforoom: 'Informace o roletách',
  menu_roller_actualposition: 'Aktuální pozice',
  menu_roller_settingposition: 'Nastaveno',
  menu_roller_settings: 'Přiřazení rolet/žaluzií',
  menu_roller_central_button: 'Centrální tlačítko',
  menu_roller_central_error: 'Chyba při komunikaci centrálního tlačítka',
  menu_roller_central_on: 'Tlačítko zapnuto',
  menu_roller_central_off: 'Tlačítko vypnuto',
  menu_roller_manualchange: 'Ruční změna',
  menu_roller_manualsend: 'Odeslat',
  menu_roller_manualselect: 'Vyberte roletu/žaluzii',
  menu_roller_programsettings: 'Roletové režimy',
  menu_roller_circuitsetting: 'Přiřazení režimů roletám',
  menu_roller_masscircuitsetting: 'Hromadné přiřazení režimů',
  menu_roller_windsensor: 'Senzor větru',
  menu_roller_position: 'Poloha',
  menu_roller_position0: 'Otevřeno',
  menu_roller_position1: 'Zavřeno',
  menu_roller_position2: 'Šterbiny',
  menu_roller_position3: 'Mezipoloha',
  menu_roller_rotation: 'Natočení (pouze žaluzie)',
  menu_roller_regimeset: 'Nastavení režimu',
  menu_roller_regimedelete: 'Smazat celý režim',
  menu_roller_regimewarning1: 'Musí být nastaven jeden den nebo celý týden.',
  menu_roller_regimewarning2: 'Předcházející režim nesmí být prázdný!',

  menu_roller_windsensor_on: 'Senzor je aktivní',
  menu_roller_windsensor_off: 'Senzor není aktivní',
  menu_roller_windsensor_undetected: 'Senzor je nedostupný',
  menu_roller_windsensor_speed: 'Aktuální rychlost větru',
  menu_roller_windsensor_alarm_speed: "Rychlost větru během posledního alarmu",
  menu_roller_windsensor_alarm: 'ALARM větrného čidla!',
  menu_roller_windsensor_lastalarm: 'Poslední alarm',

  menu_setting_fw: 'Verze firmware',
  menu_setting_fw_undetected: 'Nezjištěno',

  menu_setting_datetime: 'Nastavení času a datumu',
  menu_setting_datetime1: 'Čas a datum',
  menu_setting_datetime_text: 'Nastavení času a datumu řídící jednotky. Aktuální hodnota je důležitá pro správné fungování regulace.',
  menu_setting_datetime_getcurrenttime: 'Získat aktuální čas',

  back_compatibility: 'Topení - mód zpětné kompatibility',
  action_loading: 'Načítám ...',
  action_pooling_data: 'Přetažením dolů načti znovu data',
  action_settings_saved: 'Nastavení uloženo ...',
  action_save: 'Uložit',
  action_save_circuit: 'Uložit místnosti',
  action_back: 'Zpět',
  select_room: 'Vyberte místnost pro nastavení',
  temperature_actual: 'Aktuální teplota',
  temperature_program: 'Teplota režimu',
  temperature_max_change: 'Maximální změna teploty',
  temperature_maxoffset: 'Max. možná změna',
  temperature_total: 'Celková nastavená teplota',
  temperature_manualchange: 'Ruční změna',
  temperature_low: 'Teplota útlumu',
  low_activate: 'Útlum je zapnutý',
  low_deactivate: 'Útlum je vypnutý',
  low_datetimeLowStart: 'Začátek útlumu',
  low_datetimeLowStop: 'Konec útlumu',
  low_setlow: 'Přiřazení místností',
  low_confirm_end: 'Chcete zadat datum ukončení útlumu?',
  low_confirm_end_tooltip: 'Útlum se v daný čas vypne',

  monday: 'Po',
  tuesday: 'Út',
  wednesday: 'St',
  thursday: 'Čt',
  friday: 'Pa',
  saturday: 'So',
  sunday: 'Ne',
  confirm: 'Potvrzení',
  confirm_delete_settings: 'Opravdu chcete smazat všechna přiřazení?',

  help_cooling_roomset: 'Danému dni v týdnu se musí přiřadit chladící režim. \n'+
    'Režimy se opakují v cyklech. \n'+
    'Pokud vyplníte pouze režim na pondělí bude se tento režim opakovat každý den. \n'+
    'Pokud vyplníte pondělí-neděle, bude se opakovat celý týden. \n'+
    'Celý cyklus pro chlazení může být pouze jedno-týdenní.',

  help_cooling_set: 'Módy pro chlazení:',
  help_cooling_set1: 'Deaktivováno - chlazení nepoužito',
  help_cooling_set2: 'WTR01 - chlazení se řídí dle modulu venkovní teploty WTR01',
  help_cooling_set3: 'HCS64 - chlazení se řídí dle externího signálu z modulu HCS64',
  help_cooling_set4: 'Venkovní teplota aktivace je teplota, kdy se zapíná chlazení.',
  help_cooling_set5: 'K vypnutí chlazení dojde při teplotě Venkovní teplota aktivace mínus hystereze.',
  help_cooling_set6: 'Při změně módu chlazení se musíte odhlásit/přihlásit, aby se aktualizovalo nové nastavení.',

  help_heating_low: 'Zapnutím tzv. Low režimu - útlumu dojde k nastavení útlumové požadované teploty na vybrané místnosti.\n'+
  'Začátek útlumu je vždy aktuální čas. Konec útlumu lze nastavit na pozdější dobu.\n'+
  'Standardně všechny místnosti reagují na útlumový režim. Zvolené místnosti lze níže vyřadit z této funkce.',
  help_heating_summer: 'Zapnutím tzv. Letního režimu dojde u zařazených místností k nahrání požadované teploty:',
  help_heating_summer1: '0°C pro elektrické vytápění',
  help_heating_summer2: '99°C pro vodní vytápění, koncový člen KC64 vypne zdroj, o půlnoci dojde pravidelně k zavření a otevření ventilů proti zalehnutí',
  help_heating_summer3: 'Standardně všechny místnosti reagují na letní režim. Zvolené místnosti lze z této funkce vyřadit.',
  help_heating_floorset: 'Pro zvolenou místnost lze specifikovat min. teplotu podlahy na kterou se bude ohřívat, i když teplota vzduchu dosáhla požadované hodnoty. Toto nastavení může způsobit zvýšenou spotřebu el. energie. Hodnota 0 tuto funkci vypne.',
  help_heating_hdo: 'Regulátor pro elektrické vytápění řady RT64 je vybaven blokováním vytápění v případě vysokého tarifu. Informace o tarifu není poskytována u vodního vytápění.',
  help_heating_roommaxset: 'Pro zvolenou místnost lze specifikovat max. možnou velikost ruční změny na tlačítkách.',
  help_heating_inforoom: 'Zde jsou zobrazeny aktuální a požadované teploty a dále i ruční změny.',
  help_heating_inforoom1: 'Formát: aktuální teplota | požadovaná teplota',
  help_heating_inforoom2: 'Výpis lze obnovit přetažením dolů. Žlutou barvou jsou označeny ruční změny.',
  help_heating_inforoom3: 'Kliknutím na položku přejdete k přiřazení režimu pro vybranou místnost.',
  help_heating_inforoom4: 'Posunem řádku doleva můžete ručně změnit teplotu.',
  help_heating_inforoom5: 'Ikonou teploměru zobrazíte všechny teploty včetně logicky navázaných podlahových čidel.',
  heating_info_icon_manual: "Ruční změna",
  heating_info_icon_heating: "Topí",
  heating_info_icon_low: "Low režim",
  heating_info_icon_summer: "Letní režim",
  heating_info_icon_open: "Otevřené okno",
  heating_info_icon_guest: "Host",
  heating_info_icon_cool: "Chlazení",
  heating_info_icon_err: "Chyba čidla",
  heating_info_icon_inactive: "Neaktivní",
  help_heating_roomset: 'Danému dni v týdnu se musí přiřadit topný režim. Režimy se opakují v cyklech. Pokud vyplníte pouze režim na pondělí, bude se tento režim opakovat každý den. Pokud vyplníte pondělí-neděle, bude se opakovat celý týden.\n'+
  'Celý cyklus může být až tří-týdenní. Při jeho nastavení se specifikuje počáteční týden.',
  help_heating_roomset1: 'Den zvýrazněný modře označuje aktuálně platný den při vícedenním cyklu.',
  help_regime_assignment: 'Kliknutím na ikonu {ic} se otevře nastavení vybraného režimu.',
  help_heating_kcnet: 'Koncový člen (KCNET) odepíná topný zdroj jestliže jsou všechny místnosti natopené na požadovanou teplotu.',
  help_heating_kcnet1: 'V systému pro vodní vytápění může být až 8 koncových členů a jeden globální koncový člen.',
  help_heating_kcnet2: 'Status může být:',
  help_heating_kcnet3: 'nepoužito - KCNET není instalován',
  help_heating_kcnet4: 'nekomunikuje - KCNET není dostupný, chyba',
  help_heating_kcnet5: 'zapnuto - topný zdroj topí',
  help_heating_kcnet6: 'vypnuto - topný zdroj netopí - je odstaven',
  help_heating_kcnet7: 'Koncové členy pro topení jsou avizovány ikonou sluníčka ',
  help_heating_kcnet8: 'Koncové členy mohou být použity i pro funkci chlazení. V tom případě je funkce avizována ikonou vločky ',
  help_heating_manualset: 'Pro zvolenou místnost lze ve stanovených mezích ručně navýšit nebo snížit požadovanou teplotu.\n'+
    'Tato úprava se automaticky vymaže s další změnou požadované teploty z režimu.',
  help_heating_regimeset: 'Nastavení teplotního režimu. Je k dispozici až 8 změn za den. První režim vždy začíná v čase 00:00.',
  help_heating_wtr: 'Volitelný modul WTR01 umožňuje zobrazit venkovní teplotu. Modulu lze přiřadit režim, dle kterého se spíná výstupní relé. To umožňuje ovládat např. ohřev střešních oken.',
  help_roller_inforoom: 'Zde jsou zobrazeny aktuální a požadované polohy a dále žlutě i ruční změny.',
  help_roller_inforoom1: 'Výpis lze obnovit přetažením dolů. Žlutou barvou jsou označeny ruční změny.',
  help_roller_inforoom2: 'Kliknutím na položku přejdete k přiřazení režimu/programu pro vybranou místnost.',
  help_roller_inforoom3: 'Posunem řádku doleva můžete ručně změnit polohu.',
  help_roller_inforoom4: 'Aktuální poloha je udávána v % doby pojezdu. Poloha 0% znamená otevřeno, poloha 100% je zavřeno. Naklopení lamel u žaluzií je od 0 vodorovně, až po 10 svislá poloha.',
  help_roller_inforoom5: 'Statusy ikon:',
  help_roller_inforoom6: 'Roleta',
  help_roller_inforoom7: 'Žaluzie',
  help_roller_inforoom8: 'Vyřazená roleta/žaluzie',
  help_roller_inforoom9: 'Roleta reaguje na tl. central',
  help_roller_inforoom10: 'Byla provedena ruční změna',
  help_roller_inforoom11: 'Validita komunikace, číslo udává počet chybných komunikaci rolety',
  help_roller_inforoom12: 'Chyba napájení dolů, nebyl detekován žádný proud',
  help_roller_inforoom13: 'Chyba napájení nahoru, nebyl detekován žádný proud',
  help_roller_inforoom14: 'Chyba napájení dolů, byl překročen proud',
  help_roller_inforoom15: 'Chyba napájení nahoru, byl překročen proud',

  help_roller_regimeset: 'Nastavení roletového režimu tzv. programu. Jsou k dispozici až 4 změny za den. První režim vždy začíná v čase 00:00. Pro žaluzie lze zadat naklopení lamely od 0 do 10, kde: 0-vodorovná poloha, 10-svislá poloha (zavřeno). Pro polohu otevřeno nelze zadat natočení lamely.',
  help_roller_manual: 'Pro zvolenou roletu/žaluzii lze ručně dočasně změnit polohu, jako ovládáním přímo pomocí roletových tlačítek.',
  help_roller_central: 'Zapnutím centrálního tlačítka dojde k nastavení všech přiřazených rolet na pozici definovanou u centrálního tlačítka. Standardně všechny rolety reagují na centrální tlačítko. Zvolené rolety lze níže vyřadit.',
  help_roller_wind: 'Senzor větru zajistí ochranu žaluzií při překročení dané rychlosti větru a jeho trvání po nastavenou dobu.\n'+
    'Pokud dojde k překročení hodnot, žaluzie se otevřou, tzn. zajedou do žaluziového kastlíku.',
  help_roller_room: 'Každé roletě/žaluzii se musí přiřadit časový režim-program. Musí být vyplněn jeden den nebo celý týden. Pokud vyplníte pouze program na pondělí, bude se tento program opakovat každý den. Pokud vyplníte pondělí-neděle, bude se opakovat celý týden.',
  help_devicelist: 'Seznam nalezených řídících jednotek přiřazených k tomuto účtu. Zvolte řídící jednotku kterou chcete nastavovat.',
  title_messages: "Zprávy",
  button_dismiss: "Skrýt",
  button_dismiss_all: "Skrýt vše",
  button_show_all: "Zobrazit vše",
  slides_cooling: "K dispozici pouze když je vyplé chlazení.",

  info1: "Vzdálené ovládání řidící jednotky HC64",
  info2: "Aktuálně jsou implementovány funkce pro ovládání vytápění, chlazení a rolet/žaluzií.",
  info3: "Zabezpečená komunikace SSL/TLS je aktivovaná.",
  login_delete: "Smazat účet",

  title_theme: "Vzhled",
  theme_light: "Světlý",
  theme_dark: "Tmavý",

  heating_samples: "Vzorové režimy",
  heating_samples_antifreeze: "Proti zámrz",
  heating_samples_comfort: "Komfort",
  heating_samples_max_floors: "Podlahy max",
  heating_samples_work: "Pracovní den",
  heating_samples_wend: "Víkend",
  heating_samples_vestibule: "Zádveří",
  heating_samples_bath_floor: "Koupelna podlaha",
  heating_samples_bath_ladder: "Koupelna žebřík",

  title_language: "Jazyk",
  roller_alarm_off: "Aktuálně není překročena stanovená rychlost větru a doba trvání.",

  button_frame_close: "Zpět do aplikace",

  sort_title: "Řazeno",
  sort_id: "podle ID",
  sort_abc: "abecedně",
  sort_default: "Výchozí řazení: ",
  roller_manual_all: "Použít pro všechny",

  button_cancel: "Zrušit",
  button_ok: "Potvrdit",

  app_settings: "Nastavení aplikace",
  more_rooms: "další",
}

export let i18strings = new LocalizedStrings({
  en: en,
  cs: cs
});
