//@ts-check

import React, { useState, useEffect, useContext } from "react";
import { getArrOfNames, prepareID } from '../../functions/fce-string';

import { IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle,
         IonCardContent, IonRange,
         IonIcon, IonText,
         IonToast, IonButton, IonList, IonItem, IonSelect, IonSelectOption
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

import { icons } from "../../functions/images";
import { handRight } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { parseBoolean, randomID } from "../../functions/fce-math";

import { TIMEOUT_TOAST_SAVED, sendXMPPmessage } from "../../App";
import { i18strings } from "../../functions/i18";
import { Help, Page, Wrapper } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { SelectedDeviceContext } from "../../context/GlobalContext";

const PageRollerCentral = (props : any) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(0);
  const [unsavedRooms, setUnsavedRooms] = useState(false);

  //Načtení statusu
  const [status, /*doFetchXmpp*/] = useDataXMPP({typefce: 'get', fce: '/loadCentral', param: ''}, '');

  const [centralActive, setCentralActive] = useState(false);
  const [centralError, setCentralError] = useState(false);
  const [centralPosition, setCentralPosition] = useState(0);
  const [centralRotation, setCentralRotation] = useState(0);

  const dvc = useContext(SelectedDeviceContext);

  useHandleXmpp(status.isLoading, status.data, (resultXmpp) => {
    try {
      setCentralActive(parseBoolean(resultXmpp[0]));
      if(resultXmpp[1] !== '0') setCentralError(true);
      setCentralPosition(parseInt(resultXmpp[2]));
      setCentralRotation(parseInt(resultXmpp.substr(3, 2)));
    }
    catch(e) {
      console.error(e);
    }
    finally {}
  });

  //----Načtení místností
  const [rolList] = useDataXMPP({typefce: 'get', fce: '/listOfRollerShutters', param: ''}, '');
  const [listRooms, setListRooms] = useState<{ id: number, name: string }[]>([]);

  useHandleXmpp(rolList.isLoading, rolList.data, (resultXmpp) => {
    let result : typeof listRooms = [];

    try {
      result = getArrOfNames(resultXmpp, 13).map((row, i) => {
        return { id: i, name: row };
      });
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRooms(result);
      setUnsavedRooms(false);
    }
  });

  const [central] = useDataXMPP({typefce: 'get', fce: '/centralRollerShutters', param: ''}, '');

  const defaultListRoller = () => [...Array(32)].map((r, i) => {return {id: i, checked: false}});
  const [listRoller, setListRoller] = useState(defaultListRoller());

  useHandleXmpp(central.isLoading, central.data, (resultXmpp) => {
    let result : typeof listRoller = [];

    try {
      result = getArrOfNames(resultXmpp, 1).map((row, i) => {
        return { id: i, checked: parseBoolean(row) };
      });
      setShowLoading(false);
    }
    catch(e) {
      console.error(e);
      result = [];
    }
    finally {
      setListRoller(result);
      setUnsaved(2);
    }
  });

  const ListRooms = () => {
    return (
      <>
        {listRooms.map((item) => (
          <IonItem key={item.id}>
            <IonLabel>{item.name}</IonLabel>
            <IonToggle disabled={status.err !== null} checked={listRoller[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
          </IonItem>
        ))}
      </>
    );
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    const copy = [...listRoller];
    copy[id].checked = v;
    setListRoller(copy);
    setUnsavedRooms(true);
  }

  async function SaveCentral() {
    let p = '';
    listRoller.forEach(row => p += row.checked ? "1" : "0");

    await sendXMPPmessage(dvc, randomID(), 'SET', '/saveCentralRollerShutters', p);

    setShowToastSaved(true);
    setUnsavedRooms(false);

    p = (centralActive ? "1" : "0")
     + prepareID(centralPosition)
     + prepareID(centralRotation);

    await sendXMPPmessage(dvc, randomID(), 'SET', '/saveCentral', p);

    setShowToastSaved(true);
    setUnsaved(2);
  }
  
  useEffect(()=>{
    if (centralPosition === 0) {
        setCentralRotation(0);
    }
  }, [centralPosition]);

  return (
<Wrapper loading={showLoading} help={() => setShowHelp(true)}>
  <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
    <p>{i18strings.help_roller_central}</p>
  </Help>

  <IonToast
    isOpen={showToastSaved}
    onDidDismiss={() => setShowToastSaved(false)}
    message={i18strings.action_settings_saved}
    duration={TIMEOUT_TOAST_SAVED}
  />

  <Page title={i18strings.menu_roller_central_button} icon={handRight} unsaved={unsavedRooms || !!(unsaved & 1)}>
    <IonCard>
      {centralError ? 
      <IonCardHeader>
        <IonCardSubtitle>{i18strings.menu_roller_central_error}</IonCardSubtitle>
      </IonCardHeader>
      : ""}
      <IonCardContent>
        <IonList>
          <IonItem>
            <IonLabel>{centralActive ? i18strings.menu_roller_central_on : i18strings.menu_roller_central_off}</IonLabel>
            <IonToggle value="low" checked={centralActive} disabled={centralError}
              onIonChange={(e:any) => {setCentralActive(e.target.checked); setUnsaved(u => u | (u >> 1))}} />
          </IonItem>
          <IonItem>
            <IonLabel>{i18strings.menu_roller_position}:</IonLabel>
            <IonSelect value={centralPosition.toString()} interface="popover" disabled={centralError || !centralActive}
              onIonChange={(e) => {setCentralPosition(parseInt(e.detail.value)); setUnsaved(u => u | (u >> 1))}} >
              <IonSelectOption value="0">{i18strings.menu_roller_position0}</IonSelectOption>
              <IonSelectOption value="1">{i18strings.menu_roller_position1}</IonSelectOption>
              <IonSelectOption value="2">{i18strings.menu_roller_position2}</IonSelectOption>
              <IonSelectOption value="3">{i18strings.menu_roller_position3}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem lines="none" disabled={!centralActive || centralPosition === 0 || centralError}>
            <IonLabel>{i18strings.menu_roller_rotation}:</IonLabel>
            <IonText>{centralRotation}&nbsp;</IonText>
          </IonItem>
          <IonItem>
            <IonRange min={0} max={10} step={1} value={centralRotation}
              disabled={!centralActive || centralPosition === 0 || centralError}
              style={{padding:"0px"}}
              onIonChange={(e) => {setCentralRotation(parseInt(e.detail.value.toString())); setUnsaved(u => u | (u >> 1))}} >
              <IonIcon slot="start" icon={icons.roller0}/>
              <IonIcon slot="end" icon={icons.roller10}/>
            </IonRange>
          </IonItem>
        </IonList>
        {//<IonButton disabled={status.isErr || status.isLoading || centralError} hidden={!(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
}
      </IonCardContent>
    </IonCard>

    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>{i18strings.menu_roller_settings}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          <ListRooms/>
        </IonList>
        {//<IonButton disabled={status.isErr || status.isLoading} hidden={!unsavedRooms} expand="block" onClick={handleOnClickOKroomsAssign}>{i18strings.action_save}</IonButton>
}
      </IonCardContent>
    </IonCard>
    <IonButton disabled={status.err !== null || status.isLoading || (!unsavedRooms && !(unsaved & 1))} expand="block" onClick={SaveCentral}>{i18strings.action_save}</IonButton>
  </Page>

  {status.err}
</Wrapper>
  )
}
export default PageRollerCentral;
