//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { compareTime, replaceDiacritic, prepareID, prepareSaveTemp, addMinutes } from '../../functions/fce-string';
import { IonDateTimeRegime, MyIonRefresher, Help, Page, Wrapper } from '../../functions/myReactComponents';

import { IonLabel, IonIcon,
         IonToast, IonButton, IonList, IonItem, IonInput, IonFab, IonFabButton, IonBadge, IonAlert, IonRange, IonSelect, IonSelectOption
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { add, remove, options, pencil } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';

import { TIMEOUT_TOAST_SAVED } from "../../App";
import { i18strings } from "../../functions/i18";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { icons } from "../../functions/images";

const defaultArrRegimes = () => [{time: "00:00", position: NaN, rotate: 0}];

const PageRollerRegimeSet = ({match, refresh, setRefresh, firmware}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(0);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadProgram',
    param: prepareID(parseInt(match.params.id))
  }

  const [prog, doFetchXmpp] = useDataXMPP(defaultXmppObject, '');

  const [regimeName, setRegimeName] = useState('');
  const [regimes, setRegimes] = useState([] as ReturnType<typeof defaultArrRegimes>);

  useEffect(()=>{
    setUnsaved(u => u | (u >> 1));
  }, [regimes, regimeName]);

  useHandleXmpp(prog.isLoading, prog.data, (resultXmpp) => {
    if(resultXmpp.length === 0) return;
    setRegimeName(resultXmpp.substr(0, 13).trim());

    let regs : typeof regimes = [];
    (resultXmpp.substr(13).match(/\d\d:\d{7}/g) || []).forEach(row => {
      let pos = parseInt(row.substr(5, 3));
      regs.push({
        time: row.substr(0, 5),
        position: pos,
        rotate: pos ? parseInt(row.substr(8, 2)) : 0
      });
    });
    setRegimes(regs.length ? regs : defaultArrRegimes());
    setShowLoading(false);
    setUnsaved(4);
  });

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(defaultXmppObject);
    e.detail.complete();
  }

  const handleOnChangeInput = (e) => {
    var t = replaceDiacritic(e.detail.value);
    setRegimeName(t);
  }

  const handleOnChangeStartTime = (val: string, index: number) => {
    const newArr = [...regimes];
    let dt = "00:00";
    if (index === 0) {
      dt = compareTime(val, '00:00');
    }
    else if ((val !== '00:00') || (newArr[(index - 1 + newArr.length) % newArr.length].time !== '00:00')) {
      dt = compareTime(val, newArr[(index - 1 + newArr.length) % newArr.length].time);
    }
    newArr[index].time = dt;
    setRegimes(newArr);
  }

  const handleOnSetPosition = (e, index) => {
    let old = regimes[index].position;
    let nw = parseInt(e.detail.value);
    if (old === nw || (isNaN(old) && isNaN(nw)) || !unsaved) return;
    const newArr = [...regimes];
    newArr[index].position = nw;
    
    if(!nw) newArr[index].rotate = 0;

    setRegimes(newArr);
  }

  const handleOnSetRange = (e, index) => {
    const newArr = [...regimes];
    newArr[index].rotate = parseInt(e.detail.value);
    setRegimes(newArr);
  }

  const handleOnClickAdd = () => {
    setRegimes(r=>([] as typeof r).concat(r, [{position: NaN, rotate: 0, time: r.length ? addMinutes(r[r.length - 1].time, 5) : "00:00"}]));
  }

  const handleOnClickRemove = () => {
    setRegimes(r=>r.slice(0, -1));
  }

  const handleOnClickOK = () => {
    //!!! Místo : se musí použít '%3A'
    let p = prepareID(parseInt(match.params.id)) + regimeName.padEnd(13,'+');
    regimes.forEach(r => {
      if (!isNaN(r.position))
        p += r.time.replace(":", "%3A") + prepareSaveTemp(r.position || 0) + prepareID(r.rotate || 0);
    });

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveProgram',
      param: p
    }
    doFetchXmpp(saveXmppObject);

    setShowToastSaved(true);
    setTimeout(()=>setRefresh(!refresh), 0);
    setUnsaved(2);
  }

  return (
    <Wrapper loading={showLoading} help={() => setShowHelp(true)}>
      <MyIonRefresher onIonRefresh={doXmppRefresh} />

      <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
        <p>{i18strings.help_roller_regimeset}</p>
      </Help>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message={i18strings.action_settings_saved}
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header = {i18strings.confirm}
        message = {i18strings.confirm_delete_settings}
        buttons={[
          {
            text: i18strings.button_no,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {}
          },
          {
            text: i18strings.button_yes,
            handler: () => {
              setRegimes(defaultArrRegimes());
              setRegimes(defaultArrRegimes());  // jednou to nefunguje
              doFetchXmpp({typefce: 'SET', fce: '/deleteProgram', param: prepareID(parseInt(match.params.id))});
              setUnsaved(2);
            }
          }
        ]}
      />

      <Page title={i18strings.menu_roller_regimeset} icon={options} unsaved={!!(unsaved & 1)}>
        <IonItem color='primary'>
          <IonIcon icon={pencil} size="small" />&nbsp;&nbsp;
          <IonInput type='text' maxlength={13} value={regimeName} onIonChange={handleOnChangeInput} />
        </IonItem>
        <IonButton disabled={prog.err !== null || prog.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonList>
          {regimes.map((data, index, arr) => (
            <IonItem key={index}>
              <IonList lines="none" style={{"width": "100%"}}>
              <IonItem>
              <IonBadge>{index+1}:</IonBadge>
              <IonDateTimeRegime value={data.time} onIonChange={e=>handleOnChangeStartTime(e, index)} disabled={index === 0 && firmware < 157}
                min={index ? addMinutes(arr[index - 1].time) : "00:00"} 
                max={(index + 1 < arr.length) ? addMinutes(arr[index + 1].time, -1) : "23:55"}
              />
              <IonSelect value={data.position} onIonChange={e=>handleOnSetPosition(e, index)} interface="popover" placeholder="-----" style={{"--placeholder-opacity": "100%"}}>
                <IonSelectOption key={0} value={0}>{i18strings.menu_roller_position0}</IonSelectOption>
                <IonSelectOption key={1} value={1}>{i18strings.menu_roller_position1}</IonSelectOption>
                <IonSelectOption key={2} value={2}>{i18strings.menu_roller_position2}</IonSelectOption>
                <IonSelectOption key={3} value={3}>{i18strings.menu_roller_position3}</IonSelectOption>
              </IonSelect>
              </IonItem>
              <IonItem>
              <IonRange value={data.rotate} onIonChange={e=>handleOnSetRange(e, index)}
                disabled={!data.position} max={10} style={{padding: "0px 16px"}} />
              <IonIcon color='dark' src={icons.rotate} />
              <IonBadge color="light">{data.rotate}</IonBadge>
              </IonItem>
              </IonList>
            </IonItem>
          ))}
        </IonList>

        <IonFab vertical="top" horizontal="end" edge slot="fixed" >
          <IonFabButton color="primary" size='small' onClick={handleOnClickAdd} disabled={regimes.length >= 4}>
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabButton color="danger" size='small' onClick={handleOnClickRemove} disabled={regimes[1] === undefined}>
            <IonIcon icon={remove} />
          </IonFabButton>
        </IonFab>

        <IonButton disabled={prog.err !== null || prog.isLoading || !(unsaved & 1)} expand="block" onClick={handleOnClickOK}>{i18strings.action_save}</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton disabled={prog.err !== null || prog.isLoading} expand="block" onClick={()=>setShowAlert(true)}>{i18strings.menu_roller_regimedelete}</IonButton>
      </Page>

      {prog.err}
    </Wrapper>
  )
}
export default PageRollerRegimeSet;
