import { createContext } from 'react';

export const BwContext = createContext({bits: 0, setBits: (v: number) => {}});
export const ctxBits = {
	backcompat:       0x1,
	heating:          0x2,
	roller:           0x4,
	cooling:          0x8,
	useHdo:          0x10,
	useKcnet:        0x20,
	useWtr:          0x40,
	useWs:           0x80,
	centralEnabled: 0x100,

	dvcDep:         0x1ff,

	desktop:        0x200,
	xmppOnline:     0x400,
	loadingR:       0x800,
	loadingH:      0x1000,
}

export const SelectedDeviceContext = createContext<string | null>(null);
