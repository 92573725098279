//@ts-check

import React, { useState, useRef, useEffect, createContext, FC, Dispatch, SetStateAction, PropsWithChildren } from "react";
import { IonDatetime, IonRange, IonSelect, IonSelectOption, IonPopover, IonList, IonItem,
  IonRefresher, IonRefresherContent, IonCard, IonIcon, IonButton, IonCardContent, IonCardHeader,
  IonCardTitle, IonCardSubtitle, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton,
  IonSpinner, IonBadge, IonContent, IonToast, IonCol, IonLabel, IonTitle, IonDatetimeButton, IonModal, IonText, RefresherEventDetail } from '@ionic/react';
import { Route, Redirect, useHistory } from "react-router-dom";
import { log } from "./fce-string";
import { BwContext, SelectedDeviceContext, ctxBits } from "../context/GlobalContext"
import { i18strings } from "./i18";

import "../login.css";
import "../custom-global-themes.css";
import { helpCircleOutline, arrowDown, ellipsisVertical, optionsOutline } from "ionicons/icons";
import { TIMEOUT_TOAST_ERROR } from "../App";
import { useConifg } from "./PersistentState";
import { IonRefresherCustomEvent } from "@ionic/core";
import { CenteredPopover } from "../components/CenteredPopover";
import { SortOrder } from "./fce-math";

let idCounter = 0;

export const MyIonDateTime = (props: {
  min: undefined | string,
  value: string,
  onIonChange: (e: any) => void,
  disabled?: boolean,
}) => {
  let id = idCounter;
  idCounter++;

  return props.min === undefined || props.min === "" ? <></> : <>
    <IonDatetimeButton datetime={`datetime_${id}`} disabled={props.disabled} />

    <IonModal keepContentsMounted={true}>
      <IonDatetime
        id={`datetime_${id}`}
        min={props.min}
        max="2050"
        value={props.value}
        onIonChange={props.onIonChange}
        showDefaultButtons
        doneText={i18strings.button_ok}
        cancelText={i18strings.button_cancel}
        firstDayOfWeek={1}
      />
    </IonModal>
  </>
}

const FakeDate = "2023-03-22T"; // "Supported formats are ... HH:mm ..."
export const IonDateTimeRegime  = ({value="00:00", onIonChange=(time: string)=>{}, disabled=false, min="00:00", max="23:55"}) => {
  let id = idCounter;
  idCounter++;

  return min === "" ? <></> : <>
    <IonDatetimeButton datetime={`datetime_${id}`} disabled={disabled} />

    <IonModal keepContentsMounted={true}>
      <IonDatetime
        id={`datetime_${id}`}
        value={FakeDate + value}
        onIonChange={e => {
          return onIonChange((e.detail.value as string).split('T')[1].slice(0,5));
        }}
        minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
        min={FakeDate + min}
        max={FakeDate + max}
        showDefaultButtons
        doneText={i18strings.button_ok}
        cancelText={i18strings.button_cancel}
        presentation="time"
      />
    </IonModal>
  </>
}

export const IonRangeRegime  = (props : {
  value: number,
  onIonChange: (event: CustomEvent<any>) => void,
  disabled?: boolean,
  min?: number,
  max?: number,
}) => {
  return (
    <>
    <IonRange
      value={props.value}
      onIonChange={props.onIonChange}
      min={props.min ?? 0}
      max={props.max ?? 80}
      step={1}
      pin={true}
      disabled={props.disabled}
    >
    </IonRange>
    </>
  )
}

export const MyIonRefresher : FC<{
  onIonRefresh : (event: IonRefresherCustomEvent<RefresherEventDetail>) => void
}> = (props) => {
  return (
    <IonRefresher
      slot="fixed"
      onIonRefresh={props.onIonRefresh}>
      <IonRefresherContent
        pullingIcon = {arrowDown}
        pullingText = {i18strings.action_pooling_data}
        refreshingSpinner = "circles"
        refreshingText = {i18strings.action_loading}>
      </IonRefresherContent>
    </IonRefresher>
  )
}

export const MyPrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    loggedIn === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export const MyLoginRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    loggedIn === false
      ? <Component {...props} />
      : <Redirect to='/heating'/>
  )} />
)

export function usePromise<T>(
  promiseOrFunction: (() => Promise<T>) | Promise<T>,
  defaultValue: T
): [T, Error | string | null, boolean] {

  log('usePromise<T>', false);

  const [state, setState] = React.useState({
    value: defaultValue,
    error: null,
    isPending: true
  });

  React.useEffect(() => {
    const promise =
      typeof promiseOrFunction === "function"
        ? promiseOrFunction()
        : promiseOrFunction;

    let isSubscribed = true;
    promise
      .then(value =>
        isSubscribed ? setState({ value, error: null, isPending: false }) : null
      )
      .catch(error =>
        isSubscribed
          ? setState({ value: defaultValue, error: error, isPending: false })
          : null
      );

    return () => {
      isSubscribed = false;
    };
  }, [promiseOrFunction, defaultValue]);

  const { value, error, isPending } = state;
  return [value, error, isPending];
}


export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const LogoutPopup : FC<{
  signoutTime : number,
  warningTime : number,
  setShowWarning : (val: boolean) => void,
  logout : () => void,
}> = ({ signoutTime, warningTime, setShowWarning, logout }) => {
    useEffect(() => {

      let warnTimeout : ReturnType<typeof setTimeout> | null = null;
      let logoutTimeout : ReturnType<typeof setTimeout> | null = null;

      function setTimeouts() {
        warnTimeout = setTimeout(() => setShowWarning(true), warningTime);
        logoutTimeout = setTimeout(logout, signoutTime);
      };

      function clearTimeouts() {
        setShowWarning(false);
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
      };

      function resetTimeout() {
        clearTimeouts();
        setTimeouts();
      };

      const events = [
        'load',
        'mousemove',
        'mousedown',
        'scroll',
        'keypress',
        'touchmove',
        'touchstart',
        'touchend',
        'touchcancel'
      ];

      for (let i in events) {
        window.addEventListener(events[i], resetTimeout);
      }

      setTimeouts();

      return () => {
        for(let i in events){
          window.removeEventListener(events[i], resetTimeout);
        }
        clearTimeouts();
      }
    }, [signoutTime, warningTime, setShowWarning, logout]);

    return <div onChange={() => setShowWarning(true)} />;  // ?
}

export const I18PopOver : FC<{
  setLanguage : (lang : "en" | "cs") => void,
  onDidDismiss : typeof IonPopover extends FC<{ onDidDismiss : infer T }> ? T : never,
  isOpen : boolean
}> = (props) => {
  return (
<IonPopover
  isOpen={props.isOpen}
  onDidDismiss={props.onDidDismiss}
  className="popover">
  <IonCard>
    <IonLabel>&nbsp;{i18strings.title_language}</IonLabel>
    <IonList>
      <IonItem onClick={() => props.setLanguage('cs')} button={true}>Čeština</IonItem>
      <IonItem onClick={() => props.setLanguage('en')} button={true}>English</IonItem>
    </IonList>
  </IonCard>
</IonPopover>
  )
}

export const Help = (props:any) => {
  return (
<CenteredPopover isOpen={props.isOpen} onDidDismiss={props.onDidDismiss}>
  <IonCard>
    <IonCardHeader>
      <h3><IonIcon icon={helpCircleOutline}/> {i18strings.help}</h3>
    </IonCardHeader>
    <IonCardContent>
      {props.children}
    </IonCardContent>
    <IonButton expand="block" onClick={props.onDidDismiss}>{i18strings.button_close}</IonButton>
  </IonCard>
</CenteredPopover>
  )
}

export const Page : FC<PropsWithChildren<{
  title: string,
  icon: string,
  subt?: string,
  unsaved?: boolean
}>> = ({title, icon, subt=undefined, children, unsaved=false}) => {
  return (
<IonCard className="welcome-card">
  <IonCardHeader class="compact">
    <IonCardTitle>
      {title + (unsaved ? "*" : "")} <IonIcon color="medium" icon={icon} />
    </IonCardTitle>
    <IonCardSubtitle>
      {subt}
    </IonCardSubtitle>
  </IonCardHeader>
  <IonCardContent class="compact" id="mrcPage">
    {children}
  </IonCardContent>
</IonCard>
  )
}

export const Wrapper : FC<PropsWithChildren<{
  loading?: boolean,
  help?: (() => void)
}>> = ({loading=false, help=undefined, children}) => {
  return (
<IonPage>
  <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text={i18strings.action_back} />
        {loading ? <IonSpinner name="circles" /> : ""}
      </IonButtons>
        <IonBadge slot="end">
          <SelectedDeviceContext.Consumer>{v=>v}</SelectedDeviceContext.Consumer>
        </IonBadge>
      <IonButtons slot="end">
        {help !== undefined ?
          <IonButton onClick={help}>?</IonButton>
          :""
        }
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  <IonContent>
    {children}
  </IonContent>
</IonPage>
  )
}

export const Message = (props: any) => {
  const date = props.date === undefined  ? "2000-01-01:00:00" : props.date;
  const dt: number[] = date.split(/[-:]/);
  return (
<IonItem>
  <table style={{width: "100%", margin: "10px 0px"}}>
    <tbody>
      <tr>
        <td colSpan={2} style={{color:"gray", fontSize: "80%"}}>
          {(new Date(dt[0], dt[1] - 1, dt[2], dt[3], dt[4])).toLocaleString()}
        </td>
      </tr>
      <tr>
        <td>
          {props.children}
        </td>
        <td style={{justifySelf: "right", textAlign: "right", width: "auto"}}>
          <IonButton onClick={props.dismis}>{i18strings.button_dismiss}</IonButton>
        </td>
      </tr>
    </tbody>
  </table>
</IonItem>
  )
}

export const ErrToast = ({isOpen, message}) => <IonToast
  isOpen={isOpen}
  message={message}
  position="bottom"
  duration={TIMEOUT_TOAST_ERROR}
  buttons={[{text: 'OK', role: 'cancel'}]}
/>

export const RegimesAssignment = (props: {
  regimeAssignments: {
    id : number,
    value : number
  }[],
  firstDayOfCycle ?: number,
  defaultRegime : number,
  possibleRegimes : {
    id : number,
    name : string
  }[],
  loc : string,
  handleSelect : (ev : any, id : number) => void
}) => {
  const h = useHistory();
  const empty = "-----";
  const week = [
    i18strings.monday,
    i18strings.tuesday,
    i18strings.wednesday,
    i18strings.thursday,
    i18strings.friday,
    i18strings.saturday,
    i18strings.sunday
  ];
  return (
<IonCardContent className="compact">
  <IonList>
    {props.regimeAssignments.map((item, i) =>
      <IonItem key={item.id} className="compactitem">
        <IonCol size="fixed" color="primary" style={{minWidth: "3.1em"}}>
          <IonText color={props.firstDayOfCycle === item.id ? "primary" : "medium"}>
            {week[item.id % 7]}:
          </IonText>
        </IonCol>
        <IonCol>
          <IonSelect value={item.value} interface="popover" onIonChange={(e) => {props.handleSelect(e, item.id)}} placeholder={empty}>
            <IonSelectOption value={props.defaultRegime} key={props.defaultRegime}>{empty}</IonSelectOption>
            {
            props.possibleRegimes.map((item, index) =>
              <IonSelectOption value={item.id} key={index}>{item.name}</IonSelectOption>
            )
            }
          </IonSelect>
        </IonCol>
        <IonCol size="fixed">
          <IonButtons>
            <IonButton aria-label="detail" hidden={item.value === props.defaultRegime} onClick={(e) => {
              e.preventDefault();
              h.push(props.loc + "/regime/" + item.value + "/" + item.value)}}
              >
              <IonIcon icon={optionsOutline} style={{fontSize: "2em"}}/>
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonItem>
    )}
  </IonList>
</IonCardContent>
  )
}

const TabContext = createContext(["", false]);
export default TabContext;

export const TabItem = ({link, icon, title, disabled=false, hidden=false, New=false}) => {
  return (
<BwContext.Consumer>
  {bc =>
    !New || !(bc.bits & ctxBits.backcompat) ?
      <TabContext.Consumer>
        {tab =>
        <IonItem routerLink={"/" + tab[0] + "/" + link} disabled={!!tab[1] || disabled} hidden={hidden}>
          <IonIcon slot="start" color="primary" icon={icon} />
          <IonLabel>
            <h2>{title}</h2>
          </IonLabel>
        </IonItem>
        }
      </TabContext.Consumer>
      : ""
  }
</BwContext.Consumer>
)}

export const TabHeader = ({dvcdis, title}) =>
  <IonHeader>
    <IonToolbar>
      <IonTitle slot='start'>{title}</IonTitle>
      <BwContext.Consumer>
        {bc => bc.bits & (ctxBits.loadingH | ctxBits.loadingR) ? <IonSpinner name="circles" /> : ""}
      </BwContext.Consumer>
      <IonBadge slot='end'>
        <SelectedDeviceContext.Consumer>{v=>v}</SelectedDeviceContext.Consumer>
      </IonBadge>
      <IonButtons slot="end">
        <IonButton onClick={dvcdis} routerLink="/deviceslist"><IonIcon icon={ellipsisVertical} /></IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>

type RoomData = {
	name: string,
	id: number,
};

export const SortButton: FC<{
	sortSetter?: Dispatch<SetStateAction<SortOrder>>
	listSetter?: Dispatch<SetStateAction<RoomData[]>>
}> = ({
	sortSetter = undefined,
	listSetter = undefined,
}) => {
	const defSO = useConifg("sort");
	const [SO, setSO] = useState(defSO.val);
	const flipped = (o: SortOrder) => (1 - o) as SortOrder;

	useEffect(() => {
		setSO(defSO.val);
	}, [defSO.val]);

	useEffect(() => {
		let order: (a: RoomData, b: RoomData) => number;

		switch (SO) {
			case SortOrder.alphabet: {
				order = (a, b) => {
					let _a = a.name.toLowerCase();
					let _b = b.name.toLowerCase();
					return _a < _b ? -1 : _a > _b ? 1 : 0;
				}
				break;
			}
			case SortOrder.id: {
				order = (a, b) => a.id - b.id;
				break;
			}
			default:
				let k: never = SO;
			throw Error(`Unimplemented sorting order: ${k}`);
		}

		if (sortSetter !== undefined) sortSetter(SO);
		if (listSetter !== undefined) listSetter(l => l.sort(order));
	}, [SO, sortSetter, listSetter]);

	return <IonButton onClick={() => setSO(flipped(SO))} style={{"marginLeft": "10px"}}>
		<IonLabel>{i18strings.sort_title}&nbsp;</IonLabel>
		<IonLabel>{[i18strings.sort_id, i18strings.sort_abc][SO]}</IonLabel>
	</IonButton>
}
