//@ts-check

import React, { useState, useMemo, useContext } from "react";

/* Theme variables */
import '../../theme/variables.css';

import { icons } from "../../functions/images";
import { MyIonRefresher, Page, Wrapper, ErrToast, SortButton } from "../../functions/myReactComponents";
import { IonList, IonItem, IonLabel, IonIcon } from "@ionic/react";
import { roomOrder } from "../../functions/fce-math";
import { hroomctx } from "../TabHeating";

const PageRoomList = ({lnk, title, icon, subt}) => {
  const [roomOrdering, setRO] = useState(0);

  const rooms = useContext(hroomctx);
  const perm = useMemo(
    () => roomOrder(rooms.entries ?? [], roomOrdering),
    [rooms.entries, roomOrdering]
  );

  return (
    <Wrapper loading={rooms.loading}>
      <MyIonRefresher onIonRefresh={e => {rooms.reload(); e.detail.complete()}} />

      <Page title={title} icon={icon} subt={subt}>
        <SortButton sortSetter={setRO} />
        <IonList>{perm.map((i, key) =>
          <IonItem key={key} routerLink={"/heating/" + lnk + "/room/" + i.toString() + "/" + rooms.entries![i].name}>
            {rooms.entries![i].idAirSensor > 0 ? <>&nbsp;<IonIcon icon={icons.thermometerFloor} size="small" /></> : ""}
            <IonLabel>{rooms.entries![i].name}</IonLabel>
          </IonItem>
        )}</IonList>
      </Page>

      <ErrToast isOpen={rooms.error !== null} message={rooms.error} />
    </Wrapper>
  )
}
export default PageRoomList;