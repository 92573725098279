//@ts-check

import React, { useState, useEffect, FC, useReducer, useRef, useContext } from "react";
import { Redirect } from "react-router-dom";
import { IonPage, IonInput, IonLabel, IonCard, IonCardHeader,
         IonCardSubtitle, IonCardContent, IonCardTitle,
         IonContent, IonToast, IonButton, IonList, IonItem,
         IonHeader, IonToolbar, IonTitle, IonButtons, IonIcon,
         IonPopover, IonText, IonCheckbox, IonSelect, IonSelectOption, IonRow, IonCol, useIonActionSheet } from '@ionic/react';

import './login.css';

import axios from 'axios';
import * as CryptoJs from "crypto-js";

import { encrypt, decrypt, toBase64 } from "./functions/fce-string";
import { informationCircle, logInOutline, flag, colorPalette, eyeOutline, eyeOffOutline } from "ionicons/icons";
import { TIMEOUT_TOAST_ERROR, applyTheme } from "./App";
import { i18strings } from './functions/i18';
import { I18PopOver } from "./functions/myReactComponents";

import { Directory, Filesystem } from "@capacitor/filesystem"
import { Share } from "@capacitor/share"

import config from './config.json';
import { BwContext, ctxBits } from "./context/GlobalContext";
import { emmptyProfile, Profile, useConifg } from "./functions/PersistentState";
import { CenteredPopover } from "./components/CenteredPopover";
import { icons } from "./functions/images";
//import { AppSettings } from "./components/AppSettings";

const ADDR = '.bmr.cz/';

export function ParseUsers(json : string, successRef? : { val : boolean }) : Profile[] {
  let res : Profile[] = [];
  if (successRef)
    successRef.val = false;

  try {
    let obj = JSON.parse(json);
    let profs = Object.keys(obj).map(name => ({
      name: name,
      password: decrypt(obj[name]),
      save: true,
      savePwd: obj[name] !== ""
    }));

    for (let i = 0; i < profs.length; i++) {
      if (res.every(p => p.name !== profs[i].name))
        res.push(profs[i]);
    }
    if (successRef)
      successRef.val = true;
  } catch {
    console.error("Failed to load profiles");
  }

  res.push(emmptyProfile());
  return res;
}

export function StringifyUsers(users : Profile[]) {
  let res = {};
  for (let i = 0; i < users.length; i++) {
    if (users[i].save)
      res[users[i].name] = users[i].savePwd ? encrypt(users[i].password) : "";
  }
  return JSON.stringify(res);
}

export async function BareAuth(name : string, password : string, onError : (err : string) => void) {
  try {
    let result = await axios({
      method: 'post',
      url: config.URL_PREFIX + 'hc64' + ADDR + 'auth/Authorize.php',
      headers: {'Content-Type': 'applications/json' },
      data: {
        auth: {
          uid: btoa(unescape(encodeURIComponent(name))),
          passphrase: CryptoJs.MD5(password).toString(),
        }
      }
    })

    if (result.status === 200 && result.data) {
      var response : { // infered type:
        auth : string,
        uid : string,
        passwd : string,
        timeout : number,
        status : string,
      } = result.data[0];

      if (response.auth === 'true') {
        return response.timeout;
      }
      else onError(parseInt(response.status) === -1
        ? i18strings.error_login
        : i18strings.formatString(i18strings.error_login_otherUser, response.status).toString())
    }
    else onError(i18strings.error_login);
  } catch (e : any) {
    onError(i18strings.error_server + e.message);
  }
  return false;
}

const Login : FC<{
  setAuthTokens : (data : {uid : string, passwd : string, timeout : number} | undefined) => void,
  loggedIn : boolean
}> = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [showForget, setShowForget] = useState(false);
  const [showTheme, setShowTheme] = useState(false);
  const [site, setSite] = useState("");
  const [, redraw] = useReducer((v : number) => v + 1, 0);
  const [showPwd, setShowPwd] = useState(false);
  const bwCtx = useContext(BwContext);
  const [showProfExp] = useIonActionSheet();

  const psel = useRef<HTMLIonSelectElement>(null);
  const loaded = useRef(false);

  const locale = useConifg("locale");
  const theme = useConifg("theme");
  const users = useConifg("users");
  const selUser = useConifg("lastUser");

  useEffect(() => {
    applyTheme(theme.val);
  }, [theme.val]);

  const profiles = useRef<Profile[]>([{
    name: "",
    password: "",
    savePwd: false,
    save: false,
  }]);
  const pid = useRef(0);

  useEffect(() => {
    profiles.current = users.val;
  }, [users.val]);

  useEffect(() => {
    let i = users.val.length - 1;
    while (i > 0 && users.val[i].name !== selUser.val) i--;
    pid.current = i;
    selUser.setVal(users.val[pid.current].name);
    redraw();
  }, [users.val, selUser.val]);

  useEffect(() => {
    i18strings.setLanguage(locale.val);
  }, [locale.val]);

  useEffect(() => {
    props.setAuthTokens(undefined);

    loaded.current = true;
    return () => {
      loaded.current = false;
    }
  }, []);

  function ImportProfiles() {
    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.addEventListener("click", console.log);
    input.addEventListener("input", (e) => {
      // @ts-ignore
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      let reader = new FileReader();
      reader.onload = e => {
        let sr = { val : false };
        let profs = ParseUsers(e.target?.result?.toString() ?? "{}", sr);

        let empty = profiles.current.pop()!;
        for (let i = 0; i < profs.length; i++) {
          if (profiles.current.every(p => p.name !== profs[i].name))
            profiles.current.push(profs[i]);
        }
        profiles.current.push(empty);

        if (loaded.current) {
          if (sr.val) {
            setErrorMessage(i18strings.login_import_success);
            users.setVal(profiles.current);
          } else {
            setErrorMessage(i18strings.login_import_fail);
          }
        }
      }
      reader.readAsText(file);
    });
    document.body.appendChild(input);
    input.click()
    document.body.removeChild(input);
  }

  function ExportProfiles() {
    let json = StringifyUsers(profiles.current);

    if (bwCtx.bits & ctxBits.desktop) {
      // pc
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(json));
      element.setAttribute('download', "users.json");
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setErrorMessage(i18strings.login_export_success);
    } else {
      // iPhone by to mel umet
      // android dela neprijemnosti
      Filesystem.writeFile({
        path: "bmr_hc64_users.json",
        data: toBase64(json),
        directory: Directory.Cache
      }).then(() => Filesystem.getUri({
        path: "bmr_hc64_users.json",
        directory: Directory.Cache
      })).then((uri) =>
      Share.share({
        title: "bmr_hc64_users.json",
        url: uri.uri
      })).then(() => {
        setErrorMessage(i18strings.login_export_success);
      });
    }
  }

  const postLogin = (e)=> {
    e.preventDefault();

    if (pid.current > profiles.current.length
      //|| profiles.current[pid.current].password === ""
      //|| profiles.current[pid.current].name === ""
    ) return; // fail

    const u = profiles.current[pid.current];

    BareAuth(u.name, u.password, setErrorMessage)
    .then(res => {
      if (res !== false) {
        props.setAuthTokens({ passwd: u.password, timeout: res, uid: u.name });

        users.ForceSave(profiles.current);
        selUser.ForceSave(u.name);
      }
    });
  }

  const parentCallbackOnSelectLanguage = (lang : "en" | "cs") => {
    i18strings.setLanguage(lang);
    locale.setVal(lang);
    setShowSelectLanguage(false);
  }

  return (
    !locale.loaded || !theme.loaded ? <IonPage style={{backgroundColor : "unset"}} /> :
  props.loggedIn
  ? <Redirect to="/deviceslist"/>
  : <IonPage id='login-content'>
      <IonHeader>
        <IonToolbar>
          <BwContext.Consumer>
            {v => v.bits & ctxBits.desktop ?
            <IonTitle slot="start">
              <img src={theme.val=== "dark" || (theme.val=== "default" && document.body.classList.contains("dark")) ? icons.bmrlogodark : icons.bmrlogoplain} className="logo" alt="BMR" />
            </IonTitle>:
            ""}
          </BwContext.Consumer>
          <IonTitle slot="start">{i18strings.title_app}&nbsp;&nbsp;&nbsp;&nbsp;</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={()=>setShowTheme(true)}><IonIcon icon={colorPalette}/></IonButton>
            <IonButton onClick={()=>setShowSelectLanguage(true)}><IonIcon icon={flag}/></IonButton>
            <IonButton onClick={()=>setShowInfo(true)}>?</IonButton>
            {/*<AppSettings />*/}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div hidden={!site}
          style={{position: "absolute", height: "100%", width: "100%", zIndex: 1000, backgroundColor: "var(--ion-background-color)", overflow: "hidden", display:"flex", flexDirection: "column"}}>
          <IonButton onClick={()=>setSite("")} expand="block">{i18strings.button_frame_close}</IonButton>
          <iframe src={site} style={{flexGrow: 1, border: "none", margin: 0, padding: 0}}></iframe>
        </div>

      <IonPopover
        isOpen={showTheme}
        onDidDismiss={e => setShowTheme(false)}
        class="popover"
      >
        <IonCard>
          <IonLabel>&nbsp;{i18strings.title_theme}</IonLabel>
          <IonButton color="light" className="const" expand="block" onClick={()=>theme.setVal("light")}>
            {i18strings.theme_light}
          </IonButton>
          <IonButton color="dark" className="const" expand="block" onClick={()=>theme.setVal("dark")}>
            {i18strings.theme_dark}
          </IonButton>
        </IonCard>

      </IonPopover>

      <I18PopOver
        isOpen={showSelectLanguage}
        onDidDismiss={()=>setShowSelectLanguage(false)}
        setLanguage={parentCallbackOnSelectLanguage}
      />

      <CenteredPopover isOpen={showInfo} onDidDismiss={() => setShowInfo(false)}>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{i18strings.title_info} <IonIcon icon={informationCircle}/></IonCardTitle>
            <IonCardSubtitle>
              {i18strings.title_version} {config.version}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="primary">
              <p>{i18strings.info1}</p>
              <p>{i18strings.info2}</p>
              <p>{i18strings.info3}</p>
              <p>© BMR s.r.o. 2020</p>
            </IonText>
          </IonCardContent>
          <IonButton expand='block' onClick={()=>setShowInfo(false)}>{i18strings.button_close}</IonButton>
        </IonCard>
      </CenteredPopover>

      <IonPopover isOpen={showForget} onDidDismiss={() => setShowForget(false)} class='popover'>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{i18strings.login_forget_prompt}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonRow>
              <IonCol>
                <IonButton expand='block' onClick={() => setShowForget(false)}>{i18strings.button_no}</IonButton>
              </IonCol>
              <IonCol>
                <IonButton color='danger' expand='block' onClick={() => {
                  users.setVal(profiles.current.filter((_, i) => i !== pid.current));
                  selUser.setVal(profiles.current[0].name);
                  setShowForget(false);
                  //psel.current!.forceUpdate();
                }}>{i18strings.button_yes}</IonButton>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonPopover>

        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardSubtitle>{i18strings.title_app_welcome}</IonCardSubtitle>
            <IonCardSubtitle>
              {i18strings.formatString(
                i18strings.title_register1,
                <a href="" onClick={e=>{e.preventDefault(); setSite(config.URL_PREFIX + 'xmpp' + ADDR + 'register')}}>{i18strings.title_register}</a>)
              }
            </IonCardSubtitle>
            <IonCardSubtitle>{i18strings.login_input}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent style={{padding:"0px 20px"}}>
            <form onSubmit={postLogin}>
              <IonList>
                <IonItem lines="none">
                  <IonLabel>{i18strings.login_profile}</IonLabel>
                  <IonSelect ref={psel} value={pid.current} onIonChange={(ev) => {
                      pid.current = ev.detail.value;
                      redraw();
                    }}>
                    {profiles.current.map((v, i) => <IonSelectOption value={i} key={i}>
                      {v.name === "" ? i18strings.login_profile_new : v.name}
                    </IonSelectOption>)}
                  </IonSelect>
                </IonItem>

                <IonItem className="compactitem">
                  <IonLabel position="stacked">{i18strings.login_name}</IonLabel>
                  <IonInput
                    class='inputlogin'
                    name="username"
                    required
                    type="text"
                    maxlength={13}
                    minlength={5}
                    autocorrect='off'
                    value={profiles.current[pid.current].name}
                    onIonChange={e => {
                      profiles.current[pid.current].name = (e.detail.value!).toLowerCase();
                      if (profiles.current[profiles.current.length - 1].name !== "") {
                        profiles.current.push({ name: "", password: "", save: false, savePwd: false });
                      }
                    }} />
                </IonItem>
                <IonItem className="compactitem">
                  <IonLabel position="stacked">{i18strings.login_password}</IonLabel>
                  <IonInput
                    class='inputlogin'
                    name="password"
                    required
                    type={showPwd ? "text" : "password"}
                    maxlength={13}
                    autocorrect='off'
                    autocomplete='off'
                    value={profiles.current[pid.current].password}
                    onIonChange={e => profiles.current[pid.current].password = e.detail.value!}
                  />
                  <IonButtons slot="end" style={{alignSelf: "flex-end", padding: "8px", marginLeft: 1}}>
                    <IonButton onClick={()=>{setShowPwd(v => !v)}} style={{}}>
                      <IonIcon icon={showPwd ? eyeOutline : eyeOffOutline} />
                    </IonButton>
                  </IonButtons>
                </IonItem>
              </IonList>
              <IonItem lines='none'>
                <IonCheckbox
                  checked={profiles.current[pid.current].save}
                  onIonChange={ev => {
                    profiles.current[pid.current].save = ev.detail.checked;
                    if (!ev.detail.checked) {
                      profiles.current[pid.current].savePwd = false;
                      if (profiles.current[pid.current].name !== "")
                        setShowForget(true);
                      //redraw();
                    }
                  }}
                />
                <IonText>&nbsp;<small>{i18strings.login_remember}</small></IonText>
              </IonItem>
              <IonItem lines='none'>
                <IonCheckbox
                  checked={profiles.current[pid.current].savePwd && profiles.current[pid.current].save}
                  onIonChange={ev => {
                    profiles.current[pid.current].savePwd = ev.detail.checked;
                    if (ev.detail.checked) {
                      profiles.current[pid.current].save = true;
                      redraw();
                    }
                  }}
                />
                <IonText>&nbsp;<small>{i18strings.login_remember_pwd}</small></IonText>
              </IonItem>
              <IonButton type="submit" expand="block" routerLink="/deviceslist">{i18strings.login} <IonIcon icon={logInOutline}></IonIcon></IonButton>
              <input type="submit" style={{visibility:"hidden", position:"absolute"}} />
            </form>
            <IonList>
            <IonItem lines='none'>
              <small><a href="" onClick={e=>{e.preventDefault(); setSite(config.URL_PREFIX + 'xmpp' + ADDR + 'register/resetaccount.php')}}>{i18strings.login_forgot_password}</a></small>
            </IonItem>
            <IonItem lines='none'>
              <small><a href="" onClick={e=>{e.preventDefault(); setSite(config.URL_PREFIX + 'xmpp' + ADDR +  'register/deleteaccount.php')}}>{i18strings.login_delete}</a></small>
            </IonItem>
            <IonItem lines='none'>
              <small><a href="" onClick={e=>{
                e.preventDefault();
                showProfExp({
                  buttons: [
                    {
                      text: i18strings.login_import_profiles,
                      data: "import"
                    },
                    {
                      text: i18strings.login_export_profiles,
                      data: "export"
                    },
                    {
                      text: i18strings.button_cancel,
                      data: "cancel",
                      role: "cancel"
                    }
                  ],
                  header: i18strings.login_ie_profs,
                  onWillDismiss: ev => {
                    switch (ev.detail.data) {
                      case "import":
                        ImportProfiles();
                        break;
                      case "export":
                        ExportProfiles();
                        break;
                      default:
                        break;
                    }
                  }
                });
              }}>{i18strings.login_ie_profs}</a></small>
            </IonItem>
            </IonList>

          </IonCardContent>

          <IonToast
            isOpen={errorMessage > ''}
            onDidDismiss={() => setErrorMessage('')}
            message={errorMessage}
            position="bottom"
            duration={TIMEOUT_TOAST_ERROR}
            buttons={[
              {
                text: 'OK',
                role: 'cancel',
                handler: () => setErrorMessage('')
              }
            ]}
          />
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
export default Login;
