//@ts-check

import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar,IonContent,
         IonList, IonItem, IonLabel,
         IonNote, IonIcon, IonRow, IonCol,
         IonText, IonButton, IonSpinner, IonItemSliding, IonItemOptions, IonItemOption, IonBadge, IonGrid
} from '@ionic/react';

/* Theme variables */
import '../../theme/variables.css';

import { informationCircle, snow, sunny, card, moon, open, handRight, flame, alert, closeCircleOutline } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { useFetchRoomsXMPP } from "../../functions/fetchRoomsXMPP";
import { parseNaN2questionMarks } from "../../functions/fce-string";
import { i18strings } from "../../functions/i18";
import { MyIonRefresher, Help, Page, ErrToast, SortButton } from "../../functions/myReactComponents";
import { useHandleXmpp } from "../../functions/handleXmpp";
import { roomOrder } from "../../functions/fce-math";
import { SelectedDeviceContext } from "../../context/GlobalContext";
import { icons } from "../../functions/images";

type RoomData = {
  id: number,
  active: boolean,
  name: string,
  actualTemp: number,	// naměřená teplota
  setTemp: number,	// požadovaná teplota z režimu
  setTempTotal: number,	// teplota, na kterou se reguluje (závisí na teplotě z režimu, ručním posunu, otevření okna, ...)
  userOfset: number,
  isHeating: boolean,
  isOpenWindow: boolean,
  isGuestCard: boolean,
  validity: string,
  isLow: boolean,
  isSummer: boolean,
  isCooling: boolean,
  idAirSensor: number
}

const iconNames = ["manual", "heating", "low", "summer", "open", "guest", "cool", "err", "inactive"] as const;
type iconData = { [k in typeof iconNames[number]]: { icon : React.ReactNode, tooltip : string } };

const SensorItem = (dat: { data: RoomData, icons: iconData }) => {
  const data = dat.data;

  let setTempExplanation = "";

  // nevím, jak funguje guestCard
  if (!data.isOpenWindow && !data.isSummer) {
    if (data.userOfset < 0) {
      setTempExplanation = `= ${parseNaN2questionMarks(data.setTemp).trim()} - ${parseNaN2questionMarks(-data.userOfset).trim()}`
    } else if (data.userOfset > 0) {
      setTempExplanation = `= ${parseNaN2questionMarks(data.setTemp).trim()} + ${parseNaN2questionMarks(data.userOfset).trim()}`
    }
  }

  /*
  let setTemp : number;
  if (data.isOpenWindow) {
    setTemp = data.setTempTotal;
  } else if (!data.isGuestCard || data.isSummer) {
    setTemp = data.setTemp;
  } else {
    setTemp = data.setTempTotal - data.userOfset;
  }
  */

  return <IonRow>
    <IonCol size='5'>
      <IonLabel color='primary'>
        {data.idAirSensor > 0 ? <><IonIcon icon={icons.thermometerFloor} /><small>&nbsp;{data.name}</small></> : data.name}
      </IonLabel>
    </IonCol>
    <IonCol size='5'>
      <IonLabel color='primary'>
        {parseNaN2questionMarks(data.actualTemp)}°C |&nbsp;
        <small>
          {parseNaN2questionMarks(data.setTempTotal)}°C
          {setTempExplanation.length > 0 && <>
            <br />
            <IonText color="warning">
              {setTempExplanation}
            </IonText>
          </>}
        </small>
      </IonLabel>
    </IonCol>
    <IonCol size='2'>
      <span title="jkl">

      <IonNote>{data.userOfset !== 0 && !data.isOpenWindow && !data.isSummer ? dat.icons.manual.icon : ''}</IonNote>
      <IonNote>{data.isHeating && !data.isSummer && !data.isCooling ? dat.icons.heating.icon : ''}</IonNote>
      <IonNote>{data.isLow ? dat.icons.low.icon : ''}</IonNote>
      <IonNote>{data.isSummer ? dat.icons.summer.icon : ''}</IonNote>
      <IonNote>{data.isOpenWindow ? dat.icons.open.icon : ''}</IonNote>
      <IonNote>{data.isGuestCard ? dat.icons.guest.icon : ''}</IonNote>
      <IonNote>{data.isCooling && !data.isSummer && data.isHeating ? dat.icons.cool.icon : ''}</IonNote>
      <IonNote>{parseInt(data.validity) > 240 ? dat.icons.err.icon : ''}</IonNote>
      </span>
    </IonCol>
  </IonRow>
}

const InactiveSensorItem = (dat: { data: RoomData, icons: iconData }) => {
  let data = dat.data;
  return <IonRow>
    <IonCol size='5'>
      <IonLabel color='medium'>
        {data.idAirSensor > 0 ? <><IonIcon icon={icons.thermometerFloor} /><small>&nbsp;{data.name}</small></> : data.name}
      </IonLabel>
    </IonCol>
    <IonCol size='5'>
      <IonLabel color='medium'>
        --.- °C |&nbsp;
        <small>--.- °C</small>
      </IonLabel>
    </IonCol>
    <IonCol size='2'>
      <IonNote>
        <IonIcon color="medium" icon={closeCircleOutline} />
      </IonNote>
    </IonCol>
  </IonRow>
}

const PageHeatingInfoRoom = (props : any) => {
  const h = useHistory();
  const [showHelp, setShowHelp] = useState(false);
  const [showFloor, setShowFloor] = useState(false);
  const [roomList, setRooms] = useState(<></>);
  const [RO, setRO] = useState(0);

  const status_icons : iconData = {
    manual: { icon: <IonIcon icon={handRight} color='warning'/>, tooltip: i18strings.heating_info_icon_manual},
    heating: { icon: <IonIcon icon={flame} color='danger'/>, tooltip: i18strings.heating_info_icon_heating},
    low: { icon: <IonIcon icon={moon}/>, tooltip: i18strings.heating_info_icon_low},
    summer: { icon: <IonIcon icon={sunny} color='warning'/>, tooltip: i18strings.heating_info_icon_summer},
    open: { icon: <IonIcon icon={open}/>, tooltip: i18strings.heating_info_icon_open},
    guest: { icon: <IonIcon icon={card}/>, tooltip: i18strings.heating_info_icon_guest},
    cool: { icon: <IonIcon icon={snow} color='secondary'/>, tooltip: i18strings.heating_info_icon_cool},
    err: { icon: <IonIcon icon={alert} color='danger'/>, tooltip: i18strings.heating_info_icon_err},
    inactive: { icon: <IonIcon icon={closeCircleOutline} color="medium" />, tooltip: i18strings.heating_info_icon_inactive }
  };

  //Počet místností
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/numOfRooms',
    param: ''
  }
  const [nRooms] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  useHandleXmpp(nRooms.isLoading, nRooms.data, (v) => {doFetchXmppListRooms(defaultXmppObjectListRooms)});

  //List místností
  const defaultXmppObjectListRooms = {
    typefce: 'get',
    fce: '/moreWholeRoom',
    param: nRooms.data
  }
  const [roomsXmpp, doFetchXmppListRooms] = useFetchRoomsXMPP(
    defaultXmppObjectListRooms,
    [], 47
  );
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmppListRooms(defaultXmppObjectListRooms);
    e.detail.complete();
  }

  const handleOnChangeShowFloor = (e) => setShowFloor(!showFloor);

  useEffect (() => {
    if (roomsXmpp.isLoadingXmppListRooms || roomsXmpp.isErrorXmppListRooms) return;

    var roomData : RoomData[] = [];
    try {
      roomsXmpp.dataXmppListRooms.forEach((row, i) => {
        const room : RoomData = {
          id: i,
          active: row[0] === '1',
          name: row.substr(1,13),
          actualTemp: parseFloat(row.substr(14,5)),
          setTemp: parseFloat(row.substr(19,3)),
          setTempTotal: parseFloat(row.substr(22,5)),
          userOfset: parseFloat(row.substr(27,5)),
          isHeating: row[36] === '1',
          isOpenWindow: row[37] === '1',
          isGuestCard: row[38] === '1',
          validity: row.substr(39,3),
          isLow: row[42] === '1',
          isSummer: row[43] === '1',
          isCooling: row[44] === '1',
          idAirSensor: parseInt(row.substr(45,2)) || 0
        };

        roomData.push(room);
      });
    }
    catch {
      roomData = [];
    }

    var ro = roomOrder(roomData, RO);

    setRooms(
      <>
        {ro.map((i, key) => roomData[i].idAirSensor > 0 && !showFloor ? "" : (
          <IonItemSliding key={key}>
            <IonItem lines='full' href="" onClick={(e) => {e.preventDefault(); h.push('/heating/roomlist/room/'+roomData[i].id+'/'+roomData[i].name)}}>
              <IonGrid>
                {roomData[i].active
                ? <SensorItem data={roomData[i]} icons={status_icons} />
                : <InactiveSensorItem data={roomData[i]} icons={status_icons} />
                }
              </IonGrid>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption href="" onClick={(e) => {e.preventDefault(); h.push('/heating/manuallist/room/'+roomData[i].id+'/'+roomData[i].name)}}>
                <IonIcon icon={handRight}/>
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      </>
    );
  }, [roomsXmpp, RO, showFloor]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={i18strings.action_back} defaultHref="/heating" />
            {roomsXmpp.isLoadingXmppListRooms ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
            <IonBadge slot='end'>
              <SelectedDeviceContext.Consumer>{v=>v}</SelectedDeviceContext.Consumer>
            </IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={handleOnChangeShowFloor}><IonIcon icon={icons.thermometerFloor}></IonIcon></IonButton>
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <MyIonRefresher onIonRefresh={doXmppRefresh}></MyIonRefresher>

        <Help isOpen={showHelp} onDidDismiss={e => setShowHelp(false)}>
          <p>{i18strings.help_heating_inforoom}</p>
          <small>{i18strings.help_heating_inforoom1}</small>
          <p>{i18strings.help_heating_inforoom2}</p>
          <p>{i18strings.help_heating_inforoom3}</p>
          <p>{i18strings.help_heating_inforoom4}</p>
          <p>{i18strings.help_heating_inforoom5}</p>
          {iconNames.map((ic, i) => <Fragment key={i}>
            {status_icons[ic].icon} - {status_icons[ic].tooltip} <br />
          </Fragment>)}
        </Help>

        <Page title={i18strings.menu_heating_inforoom} icon={informationCircle}>
          <SortButton sortSetter={setRO} />
          <IonList>
            {roomList}
          </IonList>
        </Page>

        {nRooms.err}
        <ErrToast isOpen={roomsXmpp.isErrorXmppListRooms} message={roomsXmpp.errMsgXmppListRooms} />
      </IonContent>
    </IonPage>
  );
};

export default PageHeatingInfoRoom;
