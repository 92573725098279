//@ts-check

import { IonPage, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import {  informationCircle, time, mail, mailUnread, swapVertical } from 'ionicons/icons';
import React from 'react';
import './Tabs.css';
import { BwContext, SelectedDeviceContext, ctxBits } from "../context/GlobalContext"
import { i18strings } from '../functions/i18';
import TabContext, { TabItem, TabHeader } from '../functions/myReactComponents';
import { useConifg } from '../functions/PersistentState';

const TabSetting = (props) => {
  const SO = useConifg("sort");
  return (
<IonPage>
  <TabHeader dvcdis={props.dvcdis} title={i18strings.title_tab_settings} />

  <IonContent>
    <IonList lines="none">
      <IonListHeader>
        <IonLabel>{i18strings.subtitle_tab_setting}</IonLabel>
      </IonListHeader>
      <IonItem routerLink="/setting/messages">
        <IonIcon slot="start" 
          color={props.unread ? "warning" : "primary"} 
          icon={props.unread ? mailUnread : mail} />
        <IonLabel>
          <h2>{i18strings.title_messages}</h2>
        </IonLabel>
      </IonItem>
      <IonItem href="" onClick={e => {
        e.preventDefault();
        SO.setVal(v => 1 - v);
      }}>
        <IonIcon slot="start" icon={swapVertical} color="primary" />
        <IonLabel>
          <h2>{i18strings.sort_default}{[i18strings.sort_id, i18strings.sort_abc][SO.val]}</h2>
        </IonLabel>
      </IonItem>
      <BwContext.Consumer>{bits =>
      <TabContext.Provider value={["setting", false]}>
      <SelectedDeviceContext.Consumer>{unit => <>
        <TabItem link="info" title={i18strings.menu_setting_fw} icon={informationCircle} hidden={unit===null || !!(bits.bits & (ctxBits.loadingH | ctxBits.loadingR))} />
        <TabItem link="datetime" title={i18strings.menu_setting_datetime} icon={time} hidden={unit===null || !!(bits.bits & (ctxBits.loadingH | ctxBits.loadingR))} />
      </>
      }</SelectedDeviceContext.Consumer>
      </TabContext.Provider>
      }</BwContext.Consumer>
    </IonList>
  </IonContent>
</IonPage>
  );
};

export default TabSetting;
