// Samotné obrázky jsou v `public/Img`

export const icons = {
	bmrlogodark: "img/bmrlogodark.svg",
	bmrlogoplain: "img/bmrlogoplain.svg",
	central: "img/central.svg",
	roller: "img/roller.svg",
	roller0: "img/roller0.svg",
	roller10: "img/roller10.svg",
	rotate: "img/rotate.svg",
	shutter: "img/shutter-disable.svg",
	shutterDisable: "img/shutter.svg",
	thermometerFloor: "img/thermometer-floor.svg",
} as const;